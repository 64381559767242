import { useEffect } from 'react';
import { VIEW_ERROR, VIEW_VIDEOCALL } from '../../constants/views.js';

const NEW_AUDIO_ONLY_CALL = "/user/instant/audio";

const AudioOnlyRequest = (props) => {
  useEffect(() => {
    newCall(props);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
}

function newCall(props) {
  const { api, token, logout, openReloadModal, setView, venue, setMtngID } = props;
  fetch(`${api}${NEW_AUDIO_ONLY_CALL}`, {
    method: "POST",
    headers: { 'Authorization': "Bearer " + token, 'Content-Type': 'application/json' },
    body: JSON.stringify({
      venue_id: venue.toString()
    })
  }).then(response => {
    if (response.status === 200)
      return response.json();
    return Promise.reject({ status: response.status, statusText: response.statusText });
  }).then((data) => {
    if (!data) return;
    setMtngID(data.uuid);
    setView(VIEW_VIDEOCALL);
  }).catch((error) => {
    if (error.status === 401)
      logout();
    else if (error.outdated)
      openReloadModal();
    else
      setView(VIEW_ERROR);
  });
}

export default (AudioOnlyRequest);
