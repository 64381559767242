import cntr_iso_en from "i18n-iso-countries/langs/en.json";
import cntr_iso_el from "i18n-iso-countries/langs/el.json";
import cntr_iso_nl from "i18n-iso-countries/langs/nl.json";

const countries = require("i18n-iso-countries");

cntr_iso_en.countries['WC'] = "Cyprus (Kibris)";
cntr_iso_el.countries['WC'] = "Κύπρος (Kibris)";
cntr_iso_el.countries['HK'] = "Χονγκ Κονγκ";
cntr_iso_el.countries['HT'] = "Αϊτή";
cntr_iso_el.countries['GQ'] = "Ισημερινή Γουινέα";
cntr_iso_el.countries['CF'] = "Κεντροαφρικανική Δημοκρατία";

countries.registerLocale(cntr_iso_en);
countries.registerLocale(cntr_iso_el);
countries.registerLocale(cntr_iso_nl);

export { countries };
