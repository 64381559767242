import React, { useState, useEffect } from "react";
import { withTranslation } from 'react-i18next';
import { Card, CardBody, CardTitle, Button } from "reactstrap";
import { fetch } from '../util/Fetch';
import { pushHistoryState } from '../util/Util.js';
import { VIEW_ERROR, VIEW_MANAGE_BOOKING } from '../constants/views.js'

const GET_MEETINGS_WITH_CHAT = "/meeting/chat";

function ChatHistory(props) {
  const [mtngDtls, setMtngDtls] = useState([]);

  const { t } = props;
  const header =
    <div className="row">
      {/* <!-- Hero --> */}
      <div className="col-md-12 px-0">
        <div className="jumbotron bg-white rounded-0 text-center">
          <h1 className="h2 color-primary">{t('chatHistory.title')}</h1>
          <div className="lead mt-3">{t('chatHistory.descr')}</div>
          <i className="small">{t('chatHistory.disclaimer')}</i>
        </div>
      </div>
      {/* <!-- Hero End --> */}
    </div>

  const meetingsWrap = mtngDtls?.map(m => {
    return (
      <div key={m.id} className="col-md-12 my-3">
        <Card>
          <CardBody>
            <div className="row">
              <div className="col-sm-6">
                <CardTitle tag="h6"><i className="fas fa-calendar fa-sm text-muted mr-2"></i>{m.date}</CardTitle>
                <CardTitle tag="h6"><i className="fas fa-clock fa-sm text-muted mr-2"></i>{m.time}</CardTitle>
              </div>
              <div className="col-sm-6 mt-3 mt-sm-0 text-right">
                <CardTitle tag="h6">
                  <Button color="primary" onClick={() => downloadChatHistory({ ...props, id: m.id })}>{t('chatHistory.downloadBtn')}</Button>
                </CardTitle>
              </div>
            </div>
          </CardBody>
        </Card>
      </div>
    )
  });

  useEffect(() => {
    // we intentionally need to run these once during mount
    pushHistoryState(VIEW_MANAGE_BOOKING, false);
    document.body.classList.add("bg-light");
    document.documentElement.className = "cover-body";
    fetchMeetings({ ...props, setMtngDtls: setMtngDtls });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (mtngDtls.length === 0) return;
    const mtng = mtngDtls.find(m => m.id === props.id);
    if (props.id && mtng)
      downloadChatHistory(props);
  }, [props.id, mtngDtls]);

  return (
    <>
      {header}
      <div className="row mt-0">
        <div className="col-md-12 my-3">
          <Card>
            <CardBody>
              <div className="row">
                <div className="col-sm-6">
                  <h6 className="card-title">{t('chatHistory.overview', { number: mtngDtls.length })}</h6>
                </div>
              </div>
            </CardBody>
          </Card>
        </div>
        {/* <!-- Meetings wrap --> */}
        {meetingsWrap}
        {/* <!-- Meetings wrap end --> */}
      </div>
    </>
  )
}

function fetchMeetings(props) {
  const { api, user, logout, openReloadModal, setView, setMtngDtls } = props;
  fetch(`${api}${GET_MEETINGS_WITH_CHAT}`, {
    method: "GET",
    headers: { 'Authorization': "Bearer " + user, 'Content-Type': 'application/json' }
  }).then(response => {
    if (response.status === 200)
      return response.json();
    return Promise.reject({ status: response.status, statusText: response.statusText });
  }).then((data) => {
    setMtngDtls(data);
  }).catch((error) => {
    if (error.status === 401)
      logout();
    else if (error.outdated)
      openReloadModal();
    else
      setView(VIEW_ERROR);
  })
}

function downloadChatHistory(props) {
  const { api, user, logout, openReloadModal, setView } = props;
  fetch(`${api}${GET_MEETINGS_WITH_CHAT}/download?id=${props.id}`, {
    method: "GET",
    headers: { 'Authorization': "Bearer " + user, 'Content-Type': 'application/json' }
  }).then(response => {
    if (response.status === 200)
      return response.blob();
    return Promise.reject({ status: response.status, statusText: response.statusText });
  }).then((data) => {
    if (!data) return;
    const url = window.URL.createObjectURL(data);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `chat-history-${props.id}.csv`);
    document.body.appendChild(link);
    link.click();
  }).catch((error) => {
    if (error.status === 401)
      logout();
    else if (error.outdated)
      openReloadModal();
    else
      setView(VIEW_ERROR);
  })
}

export default withTranslation()(ChatHistory);