export const setStorageItem = (k, v) => {
  if (v === undefined) {
    return
  }

  if (!window.localStorage) {
    const { cookies } = this.props;
    cookies.set(k, v, { sameSite: "Lax", secure: document.location.protocol.includes("https") });
  } else {
    window.localStorage.setItem(k, v);
  }
}

export const getStorageItem = (k) => {
  var v;
  if (!window.localStorage) {
    const { cookies } = this.props;
    v = cookies.get(k);
  } else {
    v = window.localStorage.getItem(k);
  }

  if (v === "undefined") {
    return undefined;
  }

  return v;
}

export const clearStorageItem = (k) => {
  if (!window.localStorage) {
    const { cookies } = this.props;
    cookies.remove(k);
  } else {
    window.localStorage.removeItem(k);
  }
}
