import { React, useEffect, useState, useRef, useContext } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import Loader from 'react-loader-spinner';
import { withTranslation } from 'react-i18next';
import { configureAbly, useChannel } from "@ably-labs/react-hooks";
import { VIEW_VIDEOCALL } from "../../constants/views";
import ConfigContext from "../../contexts/ConfigContext";
import { liveChatIcon } from "../../util/Util";

const USER_PATH = "/user";
const GET_ABLY_TOKEN = "/ably/token";
const CANCEL_INSTANT_CALL = "/instant/cancel";

function CallWaiting({ t, uuid, isCustomer, user, api, setVisible, setMtngID, setView, instant, visible, requestCallMessage, waitingRemoteParticipants, phone, chat, bcc, audio }) {
  const [timeEstimation, setTimeEstimation] = useState(0);
  const [error, setError] = useState(false);
  const agentPickedUp = useRef(false);
  const interpreterPickedUp = useRef(false);
  const { license } = useContext(ConfigContext);

  const authUrl = api + (isCustomer ? GET_ABLY_TOKEN : USER_PATH + GET_ABLY_TOKEN)
  configureAbly({ authUrl: authUrl, authHeaders: { "Authorization": "Bearer " + user } });

  useChannel("call:" + uuid, (message) => {
    if (message.data.is_agent) {
      agentPickedUp.current = true;
    }

    if (message.data.is_interpreter) {
      interpreterPickedUp.current = true;
    }

    if (waitingRemoteParticipants.agent === true && agentPickedUp.current === false) {
      return
    }

    if (waitingRemoteParticipants.interpreter === true && interpreterPickedUp.current === false) {
      return
    }

    setVisible(false);
    setMtngID(message.data.meeting_id);
    setView(VIEW_VIDEOCALL);
  });

  const [lby] = useChannel(instant.lobby, () => { });
  // Quick fix - Cannot use "useChannel" hook conditionally
  const [lbyShared] = useChannel(instant.shared_interpreter_pool ? instant.lobby_shared : "lobby:", () => { });

  useEffect(() => {
    if (timeEstimation)
      return;
    const timeout = setTimeout(function () {
      setTimeEstimation(2); // minutes
    }, 10000); // 10 seconds
    return () => {
      clearTimeout(timeout);
    }
  }, [timeEstimation, t]);

  useEffect(() => {
    if (!timeEstimation)
      return;
    const timeout = setTimeout(function () {
      setError(true);
    }, 120000); // 2 minutes
    return () => {
      clearTimeout(timeout);
    }
  }, [timeEstimation]);

  useEffect(() => {
    if (requestCallMessage === null)
      return

    const ring_interval = setInterval(() => {
      let lbySent = false
      if (waitingRemoteParticipants.agent === true && agentPickedUp.current === false) {
        lby.publish("instant_call_request", requestCallMessage)
        lbySent = true
      }

      if (waitingRemoteParticipants.interpreter === true && interpreterPickedUp.current === false) {
        if (instant.shared_interpreter_pool) {
          lbyShared.publish("instant_call_request", requestCallMessage)
        }

        if (!lbySent) {
          lby.publish("instant_call_request", requestCallMessage)
        }
      }
    }, 10000);

    return () => clearInterval(ring_interval)
    // TBD: Line 94:6:  React Hook useEffect has missing dependencies: 'instant.shared_interpreter_pool', 'waitingRemoteParticipants.agent', and 'waitingRemoteParticipants.interpreter'. Either include them or remove the dependency array  react-hooks/exhaustive-deps
  }, [lby, lbyShared, requestCallMessage]) // eslint-disable-line react-hooks/exhaustive-deps 

  const cancelRequest = () => {
    setVisible(false);
    let lbySent = false
    if (waitingRemoteParticipants.agent === true) {
      lby.publish("cancel_instant_call_request", { uuid: uuid });
      lbySent = true
    }

    if (waitingRemoteParticipants.interpreter === true) {
      if (instant.shared_interpreter_pool) {
        lbyShared.publish("cancel_instant_call_request", { uuid: uuid });
      }

      if (!lbySent) {
        lby.publish("cancel_instant_call_request", { uuid: uuid });
      }
    }

    cancelRequestAPI();
  }

  const cancelRequestAPI = () => {
    fetch(api + (isCustomer ? CANCEL_INSTANT_CALL : USER_PATH + CANCEL_INSTANT_CALL), {
      method: "POST",
      headers: { 'Authorization': "Bearer " + user, 'Content-Type': 'application/json' },
      body: JSON.stringify({
        uuid: uuid
      })
    });
  }

  let titleTranslation = chat ? t('instantChat.requestModal.title') : t('instantCall.requestModal.title');
  const p1translation = error ? t('instantCall.waitingModal.descrError.p1') : t('instantCall.waitingModal.descr.p1');
  const p3translation = error ? t('instantCall.waitingModal.descrError.p3') : t('instantCall.waitingModal.descr.p3');

  if (audio) titleTranslation = titleTranslation + " (Audio-Only)";

  let p2translation = "";
  if (error) {
    p2translation = t('instantCall.waitingModal.descrError.p2.default');
    if (license.booking)
      p2translation = t('instantCall.waitingModal.descrError.p2.withBooking', { phone: phone });
  } else if (timeEstimation)
    p2translation = t('instantCall.waitingModal.descr.p2', { number: timeEstimation });

  const callWaitingModal =
    <Modal index={"auto"} centered={true} isOpen={visible}>
      <ModalHeader toggle={cancelRequest}>
        {chat ? bcc ? <img src={liveChatIcon} width={30} className="mb-1 mr-2" /> : <i className="fas fa fa-comment fa-sm mr-2"></i> : <i className="fas fa-video fa-sm mr-2" />}
        {titleTranslation}
      </ModalHeader>
      <ModalBody className="text-center">
        <Loader visible type="Oval" color="Black" height={60} width={60} />
        <div className="mt-3">
          <p className="h5">{p1translation}</p>
          <p className="">{p2translation}</p>
          <p className="">{p3translation}</p>
          {process.env.REACT_APP_SHOW_DEBUG_INFO === 'true' && <div className="tiny">Reference: {uuid}</div>}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button className={chat ? "invisible" : ""} color="outline-dark" onClick={cancelRequest}>{t('instantCall.waitingModal.cancelBtn')}</Button>
      </ModalFooter>
    </Modal>;

  return (callWaitingModal);
}

export default withTranslation()(CallWaiting);