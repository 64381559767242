import React from 'react';
import { fetch } from '../util/Fetch';
import withConfigContext from './withConfigContext';
import { VIEW_ERROR } from '../constants/views.js'

const CATEGORIES_DETAILS = '/categories?';

function withCategories(WrappedComponent) {
  // ...and returns another component...
  class WithCategories extends React.Component {
    constructor(props) {
      super(props);
      this.state = {}
    }

    getCategoryDetails = () => {
      let langParam = "lang=" + this.props.i18n.language;
      let venueParam = "&venue=" + this.props.venue;

      fetch(this.props.context.api + CATEGORIES_DETAILS + langParam + venueParam, {
        method: 'GET',
        headers: {
          'Authorization': "Bearer " + this.props.user,
          'Content-Type': 'application/json'
        }
      })
        .then(response => {
          if (response.status === 200)
            return response.json()
          else
            return Promise.reject({ status: response.status, statusText: response.statusText });
        })
        .then(data => {
          if (data && this.mounted) {
            this.setState({ categoriesTree: data });
          }
        })
        .catch((error) => {
          if (error.timeout)
            this.setState({ serverError: true });
          else if (error.status === 401)
            this.props.logout();
          else if (error.outdated)
            this.props.openReloadModal()
          else
            this.props.setView && this.props.setView(VIEW_ERROR)
        });
    }

    componentDidMount() {
      this.mounted = true
      this.getCategoryDetails()
    }

    componentDidUpdate(prevProps) {
      if (this.props.venue !== prevProps.venue) {
        this.getCategoryDetails()
      }
    }

    componentWillUnmount() {
      this.mounted = false
    }

    render() {
      const { forwardedRef, ...props } = this.props;
      if (!this.state.categoriesTree)
        return <></>;

      return <WrappedComponent
        getCategoryDetails={this.getCategoryDetails}
        categoriesTree={this.state.categoriesTree}
        ref={forwardedRef}
        {...props}
      />;
    }
  }

  var WithConfigContextCategories = withConfigContext(WithCategories)

  const component = React.forwardRef((props, ref) => {
    return (<WithConfigContextCategories {...props} forwardedRef={ref} />);
  });

  component.displayName = "WithConfigContextCategories";
  return component;
}

export default withCategories;
