import { useRef } from "react";

function TextToSpeechButton({ lang, text }) {
  const outputRef = useRef(null);
  const handleSubmit = () => {
    const params = `?from=${lang}&to=${lang}&text=${text}`;
    fetch(process.env.REACT_APP_PENMAN_TTS_ENDPOINT + params, {
      method: "POST"
    }).then(response => {
      if (response.status === 200)
        return response.json();
      return Promise.reject({ status: response.status, statusText: response.statusText });
    }).then((data) => {
      const newOutput = new Audio("data:audio/mp3;base64," + data.result[lang]);
      outputRef.current = newOutput;
      newOutput.play();
    }).catch((error) => {
      console.error(error);
    })
  }

  return (
    <>
      <span className="text-white ml-1 clickable" onClick={handleSubmit}>
        <i className="fas fa-bullhorn" />
      </span>
      <span className="text-white ml-2 clickable" onClick={() => outputRef.current?.pause()}>
        <i className="fas fa-stop-circle" />
      </span>
    </>
  );
}

export default TextToSpeechButton;
