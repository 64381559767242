import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { VIEW_ERROR } from '../../constants/views.js'
import ConfigContext from '../../contexts/ConfigContext';
import { nsEvenly, nsVL } from "../../util/Util";

const FINISH_CALL = "/user/meeting/finish";

class FinishCallModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      messageType: this.props.hasProducts ? "withBasket" : "noBasket",
      finishButtonPressed: false
    }
  }

  render() {
    const { t, hasProducts, close, productless } = this.props;
    const ns = productless ? nsEvenly : nsVL;
    let titleTranslation = t('call.finishModal.title', ns);
    let descrTranslation = t('call.finishModal.descr', ns);

    const finishCallModal =
      <Modal id="finishCallSession" className="modal fade show" zIndex={"auto"} tabIndex="-1" centered={true} role="dialog" isOpen={true}>
        <ModalHeader toggle={close}>
          <i className="fa fa-check-square fa-sm mr-2"></i>{titleTranslation}
        </ModalHeader>
        <ModalBody>
          <p className="small">{descrTranslation}</p>
          {hasProducts && <div className="custom-control custom-radio mb-3">
            <input type="radio" id="customRadio1" name="customRadio" className="custom-control-input" value="withBasket" onClick={this.handleChangeMessageType} defaultChecked={this.props.hasProducts} />
            <label className="custom-control-label" htmlFor="customRadio1">{t('call.finishModal.options.withBasket', ns)}</label>
          </div>}
          {!productless && <div className="custom-control custom-radio mb-3">
            <input type="radio" id="customRadio2" name="customRadio" className="custom-control-input" value="noBasket" onClick={this.handleChangeMessageType} defaultChecked={!this.props.hasProducts} />
            <label className="custom-control-label" htmlFor="customRadio2">{t('call.finishModal.options.noBasket', ns)}</label>
          </div>}
          {/* <div className="custom-control custom-radio mb-3">
            <input type="radio" id="customRadio3" name="customRadio" className="custom-control-input" value="nothing" onClick={this.handleChangeMessageType} />
            <label className="custom-control-label" htmlFor="customRadio3">{t('call.finishModal.options.nothing', ns)}</label>
          </div> */}
          {this.state.messageType === "withBasket" && <div>
            <hr />
            <div>
              <div className="custom-control custom-checkbox">
                <input type="checkbox" className="custom-control-input" id="basket-link-on-screen-rqd" name="show-basket-link-on-screen" defaultChecked disabled />
                <label className="custom-control-label" htmlFor="basket-link-on-screen-rqd">{t('call.finishModal.showBasketLinkOnScreen', ns)}</label>
              </div>
              <div className="custom-control custom-checkbox">
                <input type="checkbox" className="custom-control-input" id="send-basket-link-rqd" name="send-basket-link" defaultChecked disabled />
                <label className="custom-control-label" htmlFor="send-basket-link-rqd">{t('call.finishModal.sendBasketLink', ns)}</label>
              </div>
            </div>
          </div>}
        </ModalBody>
        <ModalFooter>
          <button type="button" className="btn btn-outline-dark" onClick={close} data-dismiss="modal">{t('call.finishModal.cancelBtn')}</button>
          <button type="button" className="btn btn-primary" onClick={this.finishCall} disabled={this.state.finishButtonPressed} data-dismiss="modal">{t('call.finishModal.finishBtn')}</button>
        </ModalFooter>
      </Modal>

    return (finishCallModal)
  }

  handleChangeMessageType = (e) => {
    this.setState({
      messageType: e.target.value
    })
  };

  finishCall = () => {
    this.setState({ finishButtonPressed: true });
    const basket = this.state.messageType === "withBasket";
    const noMessage = this.state.messageType === "nothing";

    if (noMessage) {
      this.props.resetVScusDtls();
      this.props.clearProducts();
      this.props.removeToken();
      this.props.close();
      this.props.maximiseVideoCall && this.props.maximiseVideoCall();
      return
    }

    this.props.sendBasketMessage(basket);

    var body = { mtng_id: this.props.meetingID, basket: basket };

    const { api } = this.context;
    fetch(api + FINISH_CALL, {
      method: "POST",
      headers: { 'Authorization': "Bearer " + this.props.token, 'Content-Type': 'application/json' },
      body: JSON.stringify(body)
    })
      .then(response => {
        if (response.status === 200)
          this.setState({
            messageType: "nothing"
          }, () => {
            this.props.resetVScusDtls(basket);
            this.props.clearProducts();
            this.props.removeToken();
            this.props.close();
            this.props.maximiseVideoCall && this.props.maximiseVideoCall();
          });
        else
          return Promise.reject({ status: response.status, statusText: response.statusText });
      })
      .catch((error) => {
        if (error.timeout)
          this.setState({ serverError: true });
        else if (error.status === 401)
          this.props.logout();
        else if (error.outdated)
          this.props.openReloadModal()
        else
          this.props.setView(VIEW_ERROR);
      });
  }
}

FinishCallModal.contextType = ConfigContext;

export default withTranslation()(FinishCallModal);
