import packageJson from '../../package.json';

const TIMEOUT = process.env.REACT_APP_TIMEOUT ? process.env.REACT_APP_TIMEOUT : 20000;

export const fetch = (URL, options) => {
  let didTimeOut = false;
  let sessionId;

  try {
    // grab session id
    if (!window.localStorage) {
      sessionId = document.cookie.split('; ').find(c => c.startsWith("session-id=")).split('=')[1]
    } else {
      sessionId = window.localStorage.getItem("session-id");
    }
  } catch (e) {
    console.error("session id not found")
  }

  if (options.headers) {
    options.headers = {
      ...options.headers,
      ...(!!sessionId && { ["session-id"]: sessionId }),
      'App-version': packageJson.version
    }
  } else {
    options = {
      ...options,
      headers: {
        ...(!!sessionId && { ["session-id"]: sessionId }),
        'App-version': packageJson.version
      }
    }
  }

  return new Promise((resolve, reject) => {

    const timeout = setTimeout(function () {
      didTimeOut = true;
      reject({ timeout: true });
    }, TIMEOUT);

    window.fetch(URL, options)
      .then(response => {
        if (!didTimeOut) {
          if (response.status === 403 && packageJson.version < response.headers.get('App-BC'))
            return reject({ outdated: true })
          if (response.status === 205)
            return reject({ outdated: true })

          resolve(response)
          clearTimeout(timeout)
        }
      })
      .catch(error => {
        if (!didTimeOut)
          return reject(error)
      })

  })
}
