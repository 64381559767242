// Views
export const HOMEPAGE = "homepage";
export const VIEW_HOME = "home";
export const VIEW_SCANNER = "scanner";
export const VIEW_CATALOGUE = "catalogue";
export const VIEW_WISHLIST = "wishlist";
export const VIEW_PRODUCT = "product";
export const VIEW_BASKET = "basket";
export const VIEW_ORDERS = "orders";
export const VIEW_ABOUT = "about";
export const VIEW_TNC = "tnc";
export const VIEW_SETTINGS = "settings";
export const VIEW_ERROR = "error";
export const VIEW_CHECKOUT = "checkout";
export const VIEW_ORDER_REVIEW = "order-review";
export const VIEW_BOOKING = "booking";
export const VIEW_BOOKING_BCC = "booking-bcc";
export const VIEW_BOOKING_HOME = "booking-home";
export const VIEW_MANAGE_BOOKING = "manage-booking";
export const VIEW_USER_HOME = "userhome";
export const VIEW_PVSFINISH = "pvsfinish";
export const VIEW_VENUEHOME = "venuehome";
export const VIEW_VIDEOCALL = "videocall";
export const VIEW_VIDEOCALL_END = "videocall-end";
export const VIEW_VIDEOCALL_QNR = "videocall-qnr";
export const VIEW_INSTANT_CHAT = "instant-chat"
export const VIEW_TABLET_STANDBY = "tablet-standby";
export const VIEW_NO_RETAILER = "no-ret";
export const VIEW_EVENT_VIEWER = "event-viewer";
export const VIEW_EVENT_HOST = "event-host";
export const VIEW_LEGAL = "legal";
export const VIEW_IMM_MODE = "imm-mode";
export const VIEW_IMM_MODE_TTS = "imm-mode-tts";
export const VIEW_IN_PERSON_MODE = "in-person-mode";
export const VIEW_IN_PERSON_MODE_PURE = "in-person-mode-pure";
export const VIEW_CHAT_HISTORY = "chat-history";
