import React, { useState, useEffect } from 'react';
import withCategories from '../HOCs/withCategories';
import { withTranslation, Trans } from 'react-i18next';
import classnames from 'classnames';
import { Collapse } from 'reactstrap';
import { nsVL } from '../util/Util.js';

function CategoryNav(props) {
  const [wsShoplinkActiveDesk, setWsShoplinkActiveDesk] = useState(props.categoriesTree[0].key);
  const [wsShoplinkActiveMob, setWsShoplinkActiveMob] = useState();
  const mobArrowCatClasses = classnames('wsmenu-click', { 'ws-activearrow': props.isOpen })

  useEffect(() => {
    props.i18n.on('languageChanged', props.getCategoryDetails)
    return () => {
      props.i18n.off('languageChanged', props.getCategoryDetails)
    }
  }, [props])

  const ct = props.categoriesTree.map(node => {
    let nodeLvl2 = node.children && node.children.map(node2 =>
      <div className="col-lg-4 col-md-12" key={node2.key}>
        <ul className="wstliststy02 clearfix">
          <li className="wstheading clearfix clickable"><a onClick={(e) => { e.stopPropagation(); props.onCategoryClick(node.key, node.name, node2.key, node2.name, "", "", node.banner, node2.banner, null) }}>{node2.name}</a></li>

          {node2.children && node2.children.map(node3 =>
            <li className="clickable" key={node3.key} onClick={(e) => { e.stopPropagation(); props.onCategoryClick(node.key, node.name, node2.key, node2.name, node3.key, node3.name, node.banner, node2.banner, node3.banner) }}><a>{node3.name}</a></li>)}

        </ul>
      </div>)

    return <li className={wsShoplinkActiveDesk === node.key ? "wsshoplink-active clickable" : 'clickable'} onMouseOver={() => setWsShoplinkActiveDesk(node.key)} key={node.key}>
      <span className={wsShoplinkActiveMob === node.key ? "wsmenu-click02 ws-activearrow02" : "wsmenu-click02"} onClick={(e) => { e.stopPropagation(); setWsShoplinkActiveMob(wsShoplinkActiveMob === node.key ? null : node.key) }}><i className="wsmenu-arrow"></i></span>
      <a onClick={(e) => { e.stopPropagation(); props.onCategoryClick(node.key, node.name, "", "", "", "", node.banner, null, null) }}>{node.name}</a>

      {props.hamburger
        ? <Collapse isOpen={wsShoplinkActiveMob === node.key} className="wstitemright clearfix">
          <div className="container-fluid">
            <div className="row">
              {nodeLvl2}
            </div>
          </div>
        </Collapse>
        : <div className="wstitemright clearfix">
          <div className="container-fluid">
            <div className="row">
              {nodeLvl2}
            </div>
          </div>
        </div>}

    </li>
  }
  )


  return <li onClick={props.onCollapse}>
    <span className={mobArrowCatClasses} onClick={props.onCollapse}>
      <i className="wsmenu-arrow"></i>
    </span>
    <a className="navtext clickable">
      <Trans ns={nsVL.ns} i18nKey="categoryNav.title">
        Smpl idx 0
        <span className="h2 mb-0">Smpl idx 1</span>
        <span>Smpl idx 2</span>
      </Trans>
    </a>

    {props.hamburger
      ? <Collapse isOpen={props.isOpen} className="wsshoptabing wtsdepartmentmenu clearfix cat-nav-scrollable">
        <div className="wsshopwp clearfix">
          <ul className="wstabitem clearfix">
            {ct}
          </ul>
        </div>
      </Collapse>
      : <div className="wsshoptabing wtsdepartmentmenu clearfix cat-nav-scrollable">
        <div className="wsshopwp clearfix">
          <ul className="wstabitem clearfix">
            {ct}
          </ul>
        </div>
      </div>}





  </li>;
}


export default withTranslation()(withCategories(CategoryNav));