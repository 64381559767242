import { useEffect, useState } from 'react';
import { VL_ASSETS_URL, pushHistoryState } from '../util/Util';
import { VIEW_LEGAL } from '../constants/views';

function Legal(props) {
  const { legals, legalType, friendlyID, product, lang, anchor } = props;
  const [loading, setLoading] = useState(false);
  const [rawHTML, setRawHTML] = useState(false);
  const [legalLink, setLegalLink] = useState(null);

  useEffect(() => {
    pushHistoryState(VIEW_LEGAL);
  }, []);

  useEffect(() => {
    if (!rawHTML)
      return;
    document.getElementById(anchor)?.scrollIntoView();
  }, [rawHTML, anchor]);

  useEffect(() => {
    let tenantID = "common", legalFormat = "html";
    if (legalType) {
      if (legals?.[legalType]) {
        tenantID = friendlyID;
        legalFormat = legals[legalType];
      }
      const link = `${VL_ASSETS_URL}/legal/${product}_${tenantID}_${legalType}_${lang}.${legalFormat}`;
      setLegalLink(link);
    }
    if (!legalLink || legalFormat !== "html")
      return;

    setLoading(true);
    fetch(legalLink, {
      method: 'GET',
    })
      .then(response => {
        if (response.status === 200)
          return response.text();
      })
      .then(data => {
        setLoading(false);
        if (!data)
          return;
        setRawHTML(data);
      });
  }, [friendlyID, lang, legalType, legals, product, legalLink]);

  if (!loading) {
    if (rawHTML)
      return (<div dangerouslySetInnerHTML={{ __html: rawHTML }} />);
    return (
      <div className="embed-responsive embed-responsive-1by1">
        <iframe
          src={legalLink}
          allowFullScreen
          className="embed-responsive-item position-fixed"
          title="legal">
        </iframe>
      </div>
    );
  }

  return (<></>);
}

export default Legal;
