import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Trans } from 'react-i18next';
import { withCookies } from 'react-cookie';
import { Button } from 'reactstrap';
import classnames from 'classnames';

class CookieConsentBanner extends Component {

  render() {
    const { t, handleLegal } = this.props

    return (
      <div id="cookiePop" className={classnames('cookieConsent', { 'cookieShift': !this.props.isFullScreen }, 'text-center', 'text-sm-left')}>
        <div className="d-flex justify-content-between explanation">
          <p><Trans i18nKey="policyTerms.descr"> <Button color="link" onClick={(e) => handleLegal('cookies', e)} /></Trans>
          </p>
        </div>

        <div className="mx-auto mx-sm-0 mb-4 mb-sm-0">
          <button id="cookieConfirm" className="btn-warning btn-sm mt-0" onClick={this.props.onAccept}>{t('policyTerms.acceptBtn')}</button>
          <button data-toggle="modal" data-target="#cookieOptionsModal" className="btn btn-link btn-sm mt-0 mr-3" id="options" onClick={this.props.onOptions}>{t('policyTerms.options')}</button>
        </div>
      </div>
    );
  }
}
export default withTranslation()(withCookies(CookieConsentBanner));
