import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { withTranslation } from 'react-i18next';

class TimeOutModal extends Component {
  render() {
    const { t } = this.props;

    return (
      <Modal centered={true} isOpen={this.props.serverError} toggle={this.handleCloseModalError}>
        <ModalHeader>
          <i className="fas fa-exclamation-triangle fa-sm mr-2"></i>{t('errorModal.title')}
        </ModalHeader>
        <ModalBody>
          <p className="large">{t('errorModal.p1')}</p>
          <p className="large">{t('errorModal.p2')}</p>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={this.handleCloseModalError}>{t('errorModal.confirmBtn')}</Button>
        </ModalFooter>
      </Modal>
    );
  }

  handleCloseModalError = () => {
    this.props.enableErrorModal(false);
  }

}

export default withTranslation()(TimeOutModal);