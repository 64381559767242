import React, { Component } from 'react';
import classnames from 'classnames';
import { withTranslation } from 'react-i18next';
import packageJson from '../../package.json';

class Footer extends Component {
  render() {
    const { t, productless, handleLegal } = this.props;
    const classes = classnames("footer", "my-3", { "sticky-footer": this.props.fullScreen });

    const versionWithProducts = t('footer.copyright') + " - v" + packageJson.version;
    const versionProductless = "Evenly - v" + packageJson.versionProductless;
    const version = productless ? versionProductless : versionWithProducts

    return (
      <footer className={classes}>
        <div className="container">
          <div className="row">
            <div className="col-md-12 d-flex justify-content-between">
              {process.env.REACT_APP_SHOW_DEBUG_INFO === 'true' && <div><small className="text-muted">{version}</small></div>}
              <div className="text-right">
                <button data-toggle="modal" data-target="#cookieOptionsModal" className="btn btn-link small text-muted ml-2"
                  type="button" onClick={this.props.onManageCookies}>{t('footer.cookies')}</button>
                <button className="btn btn-link small text-muted ml-2" onClick={(e) => handleLegal('privacy', e)}>{t('footer.privacy')}</button>
                <button className="btn btn-link small text-muted ml-2" onClick={(e) => handleLegal('tnc', e)}>{t('footer.tnc')}</button>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default withTranslation()(Footer);
