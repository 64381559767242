import React from 'react';
import { createRoot } from 'react-dom/client';

import App from './App';
import * as serviceWorker from './serviceWorker';
import './i18n.js';
import i18n from 'i18next';

const DEFAULT_NAMESPACE = 'common'

const container = document.getElementById('root');
const root = createRoot(container);

i18n.on('loaded', () => root.render(<App />))

// initialize window dataLayer if dataLayer does not exists
window.dataLayer = window.dataLayer || { push: () => { } }

// declare window gtag
function gtag() { window.dataLayer.push(arguments); }
window.gtag = gtag


if (i18n.hasResourceBundle(i18n.language, DEFAULT_NAMESPACE))
  root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
