import React from 'react';
import { fetch } from '../util/Fetch';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Alert } from 'reactstrap';
import DigitInput from 'react-digit-input';
import { withTranslation, Trans } from 'react-i18next';
import withConfigContext from './withConfigContext';
import { VIEW_ERROR } from '../constants/views.js'


const AUTH_MOB = "/auth";
const AUTH_PIN = "/auth/pin";
const PINCODE_LENGTH = 6

/** withOTP wraps component with OTP Authentication functions. Includes withTranslation HOC */
function withOTP(WrappedComponent) {

  return withTranslation()(withConfigContext(class WithOTP extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        showOTPModal: false,
        callback: null,
        pincode: "",
        pinInvalid: false,
        pinExpired: false,
      };
    }

    componentDidMount() {

    }

    componentWillUnmount() {

    }

    render() {
      const { t } = this.props;
      const { pincode, pinInvalid, pinExpired } = this.state

      const pinInvalidMsg = t('userAuth.verifyMobModal.ivldAlert');
      const pinExpiredMsg = <div><Trans i18nKey="userAuth.verifyMobModal.exprdAlert">Sample idx 0<span className="btn-link" style={{ cursor: "pointer" }} onClick={this.resendOTP}>Sample idx1</span>Sample idx2</Trans></div>;

      let alertMsg;
      if (pinInvalid)
        alertMsg = pinInvalidMsg;
      else if (pinExpired)
        alertMsg = pinExpiredMsg;

      const alert = <Alert color="danger"><strong>{t('alert.danger.title')}</strong>: {alertMsg}</Alert>

      const OTPModal =
        <Modal centered={true} isOpen={this.state.showOTPModal} toggle={this.handleCloseOTPModal}>
          <ModalHeader>
            <i className="fas fa-sms fa-sm mr-2"></i>{t('userAuth.verifyMobModal.title')}
          </ModalHeader>
          <ModalBody>
            <p className="small">{t('userAuth.verifyMobModal.body')}</p>
            <DigitInput
              acceptedCharacters={/^[0-9]$/}
              length={PINCODE_LENGTH}
              value={pincode}
              onChange={pincode => this.setState({ pincode })}
            >
              {props => (
                <div className="row mb-2">
                  <div className="col pr-0">
                    <input className="form-control inputs" placeholder="*" type="tel" {...props[0]} onKeyPress={this.submitWithEnter} />
                  </div>
                  <div className="col pr-0">
                    <input className="form-control inputs" placeholder="*" type="tel" {...props[1]} onKeyPress={this.submitWithEnter} />
                  </div>
                  <div className="col pr-0">
                    <input className="form-control inputs" placeholder="*" type="tel" {...props[2]} onKeyPress={this.submitWithEnter} />
                  </div>
                  <div className="col pr-0">
                    <input className="form-control inputs" placeholder="*" type="tel" {...props[3]} onKeyPress={this.submitWithEnter} />
                  </div>
                  <div className="col pr-0">
                    <input className="form-control inputs" placeholder="*" type="tel" {...props[4]} onKeyPress={this.submitWithEnter} />
                  </div>
                  <div className="col">
                    <input className="form-control inputs" placeholder="*" type="tel" {...props[5]} onKeyPress={this.submitWithEnter} />
                  </div>
                </div>
              )}
            </DigitInput>
            {(this.state.pinInvalid || this.state.pinExpired) && alert}
          </ModalBody>
          <ModalFooter>
            <Button color="outline-dark" onClick={this.handleCloseOTPModal}>{t('userAuth.verifyMobModal.cancelBtn')}</Button>
            <Button color="primary" onClick={this.verifyOTP} disabled={pincode.replace(/ /g, '').length !== PINCODE_LENGTH}>{t('userAuth.verifyMobModal.confirmBtn')}</Button>
          </ModalFooter>
        </Modal>
      // ... and renders the wrapped component with the fresh data!
      // Notice that we pass through any additional props
      return (
        <div>
          <WrappedComponent resetNextButton={this.state.resetNextButton} checkOTPVerified={this.checkVerified} {...this.props} />
          {OTPModal}
        </div>

      );
    }

    submitWithEnter = (e) => {
      if (e.key === "Enter" && this.state.pincode.replace(/ /g, '').length === PINCODE_LENGTH)
        this.verifyOTP();
    }

    requestOTP = () => {
      if (this.state.pinInvalid || this.state.pinExpired || this.state.mobileSent === this.state.mobile) {
        this.handleShowOTPModal();
        return;
      }
      let mobile = this.state.mobile
      const { api } = this.props.context;
      fetch(api + AUTH_MOB, {
        method: 'POST',
        body: JSON.stringify({ mobile: mobile, lang: this.props.i18n.language }),
        headers: { 'Content-Type': 'application/json', }
      })
        .then(response => {
          if (response.status === 200) {
            this.setState({
              mobileSent: mobile
            }, this.handleShowOTPModal());
          }
          else {
            return Promise.reject({ status: response.status, statusText: response.statusText });
          }
        })
        .catch((error) => {
          if (error.timeout)
            this.setState({ serverError: true });
          else if (error.status === 400)
            this.handleInvalidMobile()
          else if (error.outdated)
            this.props.openReloadModal()
          else if (error.status === 404)
            this.handleDeletion()
          else
            this.props.setView(VIEW_ERROR)
        });
    }

    verifyOTP = () => {
      let langParam = "lang=" + this.props.i18n.language;
      let mobile = this.state.mobile
      const { api } = this.props.context;
      fetch(api + AUTH_PIN + "?" + langParam, {
        method: 'POST',
        redirect: 'error',
        body: JSON.stringify({ mobile: mobile, pin: this.state.pincode }),
        headers: { 'Content-Type': 'application/json', }
      })
        .then(response => {
          if (response.status === 200)
            return response.json();
          else
            return Promise.reject({ status: response.status, statusText: response.statusText })
        }).then(data => {
          if (data) {
            this.props.setUser(data.Token);
            this.props.removeVSToken();
            this.setState({
              showOTPModal: false
            })
            this.state.callback(data)
          }
        })
        .catch((error) => {
          if (error.timeout)
            this.setState({ serverError: true });
          else if (error.status === 400) {
            this.setState({ pinInvalid: true, pinExpired: false })
          } else if (error.status === 401) {
            this.setState({ pinInvalid: false, pinExpired: true })
          } else if (error.status === 404)
            this.handleDeletion()
          else if (error.outdated)
            this.props.openReloadModal()
          else
            this.props.setView(VIEW_ERROR)
        });
    }

    checkVerified = (mobile, callback) => {
      /*
      check verified 

      if verified {
          callback()
      }*/

      // if !verified {
      this.setState({
        callback: callback,
        mobile: mobile,
        resetNextButton: false
      }, this.requestOTP)
      // }
    }

    handleShowOTPModal = () => {
      this.setState({
        showOTPModal: true
      })
    }

    handleCloseOTPModal = () => {
      this.setState({
        showOTPModal: false,
        resetNextButton: true
      })
    }

    resendOTP = () => {
      this.setState({
        mobileSent: "",
        pincode: "",
        pinExpired: false
      }, this.requestOTP);
    }

    handleDeletion = () => {
      this.setState({ resetNextButton: true }, this.state.callback({ deletionReq: true }))
    }

    handleInvalidMobile = () => {
      this.setState({ resetNextButton: true }, this.state.callback({ invalidMobile: true }))
    }

  }))

}



export default withOTP;
