import React, { Component } from 'react';
import i18n from 'i18next';
import { withTranslation } from 'react-i18next';
import { countries } from '../util/Country';


class CountryCode extends Component {

  render() {
    const { t, defaultValue, invalid } = this.props;
    i18n.language = i18n.language.substring(0, 2)

    return (
      <select defaultValue={defaultValue} className={`custom-select d-block w-100${invalid ? " is-invalid" : ""}`} onChange={this.handleCountryCodeChange} required>
        <option defaultValue="true" value="">{t('countryCode.placeHolder')}</option>
        <option data-countrycode="GR" value="30">{countries.getName("GR", i18n.language)} (+30)</option>
        <option data-countrycode="CY" value="357"> {countries.getName("CY", i18n.language)} (+357)</option>

        <optgroup label={t('countryCode.other')}>
          <option data-countrycode="AL" value="355">{countries.getName("AL", i18n.language)} (+355)</option>
          <option data-countrycode="DZ" value="213">{countries.getName("DZ", i18n.language)} (+213)</option>
          <option data-countrycode="AD" value="376">{countries.getName("AD", i18n.language)} (+376)</option>
          <option data-countrycode="AO" value="244">{countries.getName("AO", i18n.language)} (+244)</option>
          <option data-countrycode="AI" value="1264">{countries.getName("AI", i18n.language)} (+1264)</option>
          <option data-countrycode="AG" value="1268">{countries.getName("AG", i18n.language)} (+1268)</option>
          <option data-countrycode="AR" value="54">{countries.getName("AR", i18n.language)} (+54)</option>
          <option data-countrycode="AM" value="374">{countries.getName("AM", i18n.language)} (+374)</option>
          <option data-countrycode="AW" value="297">{countries.getName("AW", i18n.language)} (+297)</option>
          <option data-countrycode="AU" value="61">{countries.getName("AU", i18n.language)} (+61)</option>
          <option data-countrycode="AT" value="43">{countries.getName("AT", i18n.language)} (+43)</option>
          <option data-countrycode="AZ" value="994">{countries.getName("AZ", i18n.language)} (+994)</option>
          <option data-countrycode="BS" value="1242">{countries.getName("BS", i18n.language)} (+1242)</option>
          <option data-countrycode="BH" value="973">{countries.getName("BH", i18n.language)} (+973)</option>
          <option data-countrycode="BD" value="880">{countries.getName("BD", i18n.language)} (+880)</option>
          <option data-countrycode="BB" value="1246">{countries.getName("BB", i18n.language)} (+1246)</option>
          <option data-countrycode="BY" value="375">{countries.getName("BY", i18n.language)} (+375)</option>
          <option data-countrycode="BE" value="32">{countries.getName("BE", i18n.language)} (+32)</option>
          <option data-countrycode="BZ" value="501">{countries.getName("BZ", i18n.language)} (+501)</option>
          <option data-countrycode="BJ" value="229">{countries.getName("BJ", i18n.language)} (+229)</option>
          <option data-countrycode="BM" value="1441">{countries.getName("BM", i18n.language)} (+1441)</option>
          <option data-countrycode="BT" value="975">{countries.getName("BT", i18n.language)} (+975)</option>
          <option data-countrycode="BO" value="591">{countries.getName("BO", i18n.language)} (+591)</option>
          <option data-countrycode="BA" value="387">{countries.getName("BA", i18n.language)} (+387)</option>
          <option data-countrycode="BW" value="267">{countries.getName("BW", i18n.language)} (+267)</option>
          <option data-countrycode="BR" value="55">{countries.getName("BR", i18n.language)} (+55)</option>
          <option data-countrycode="BN" value="673">{countries.getName("BN", i18n.language)} (+673)</option>
          <option data-countrycode="BG" value="359">{countries.getName("BG", i18n.language)} (+359)</option>
          <option data-countrycode="BF" value="226">{countries.getName("BF", i18n.language)} (+226)</option>
          <option data-countrycode="BI" value="257">{countries.getName("BI", i18n.language)} (+257)</option>
          <option data-countrycode="KH" value="855">{countries.getName("KH", i18n.language)} (+855)</option>
          <option data-countrycode="CM" value="237">{countries.getName("CM", i18n.language)} (+237)</option>
          <option data-countrycode="CA" value="1">{countries.getName("CA", i18n.language)} (+1)</option>
          <option data-countrycode="CV" value="238">{countries.getName("CV", i18n.language)} (+238)</option>
          <option data-countrycode="KY" value="1345">{countries.getName("KY", i18n.language)} (+1345)</option>
          <option data-countrycode="CF" value="236">{countries.getName("CF", i18n.language)} (+236)</option>
          <option data-countrycode="CL" value="56">{countries.getName("CL", i18n.language)} (+56)</option>
          <option data-countrycode="CN" value="86">{countries.getName("CN", i18n.language)} (+86)</option>
          <option data-countrycode="CO" value="57">{countries.getName("CO", i18n.language)} (+57)</option>
          <option data-countrycode="KM" value="269">{countries.getName("KM", i18n.language)} (+269)</option>
          <option data-countrycode="CG" value="242">{countries.getName("CG", i18n.language)} (+242)</option>
          <option data-countrycode="CK" value="682">{countries.getName("CK", i18n.language)} (+682)</option>
          <option data-countrycode="CR" value="506">{countries.getName("CR", i18n.language)} (+506)</option>
          <option data-countrycode="HR" value="385">{countries.getName("HR", i18n.language)} (+385)</option>
          <option data-countrycode="CU" value="53">{countries.getName("CU", i18n.language)} (+53)</option>
          <option data-countrycode="WC" value="90">{countries.getName("WC", i18n.language)} (+90)</option>
          {/* <!--<option data-countrycode="CY" value="90">Cyprus North (+90)</option>--> */}
          {/* <!--<option data-countrycode="CY" value="357">Cyprus (+357)</option>--> */}
          <option data-countrycode="CZ" value="42">{countries.getName("CZ", i18n.language)} (+42)</option>
          <option data-countrycode="DK" value="45">{countries.getName("DK", i18n.language)} (+45)</option>
          <option data-countrycode="DJ" value="253">{countries.getName("DJ", i18n.language)} (+253)</option>
          <option data-countrycode="DM" value="1809">{countries.getName("DM", i18n.language)} (+1809)</option>
          <option data-countrycode="DO" value="1809">{countries.getName("DO", i18n.language)} (+1809)</option>
          <option data-countrycode="EC" value="593">{countries.getName("EC", i18n.language)} (+593)</option>
          <option data-countrycode="EG" value="20">{countries.getName("EG", i18n.language)} (+20)</option>
          <option data-countrycode="SV" value="503">{countries.getName("SV", i18n.language)} (+503)</option>
          <option data-countrycode="GQ" value="240">{countries.getName("GQ", i18n.language)} (+240)</option>
          <option data-countrycode="ER" value="291">{countries.getName("ER", i18n.language)} (+291)</option>
          <option data-countrycode="EE" value="372">{countries.getName("EE", i18n.language)} (+372)</option>
          <option data-countrycode="ET" value="251">{countries.getName("ET", i18n.language)} (+251)</option>
          <option data-countrycode="FK" value="500">{countries.getName("FK", i18n.language)} (+500)</option>
          <option data-countrycode="FO" value="298">{countries.getName("FO", i18n.language)} (+298)</option>
          <option data-countrycode="FJ" value="679">{countries.getName("FJ", i18n.language)} (+679)</option>
          <option data-countrycode="FI" value="358">{countries.getName("FI", i18n.language)} (+358)</option>
          <option data-countrycode="FR" value="33">{countries.getName("FR", i18n.language)} (+33)</option>
          <option data-countrycode="GF" value="594">{countries.getName("GF", i18n.language)} (+594)</option>
          <option data-countrycode="PF" value="689">{countries.getName("PF", i18n.language)} (+689)</option>
          <option data-countrycode="GA" value="241">{countries.getName("GA", i18n.language)} (+241)</option>
          <option data-countrycode="GM" value="220">{countries.getName("GM", i18n.language)} (+220)</option>
          <option data-countrycode="GE" value="7880">{countries.getName("GE", i18n.language)} (+7880)</option>
          <option data-countrycode="DE" value="49">{countries.getName("DE", i18n.language)} (+49)</option>
          <option data-countrycode="GH" value="233">{countries.getName("GH", i18n.language)} (+233)</option>
          <option data-countrycode="GI" value="350">{countries.getName("GI", i18n.language)} (+350)</option>
          {/* <!--<option data-countrycode="GR" value="30">Greece (+30)</option>--> */}
          <option data-countrycode="GL" value="299">{countries.getName("GL", i18n.language)} (+299)</option>
          <option data-countrycode="GD" value="1473">{countries.getName("GD", i18n.language)} (+1473)</option>
          <option data-countrycode="GP" value="590">{countries.getName("GP", i18n.language)} (+590)</option>
          <option data-countrycode="GU" value="671">{countries.getName("GU", i18n.language)} (+671)</option>
          <option data-countrycode="GT" value="502">{countries.getName("GT", i18n.language)} (+502)</option>
          <option data-countrycode="GN" value="224">{countries.getName("GN", i18n.language)} (+224)</option>
          <option data-countrycode="GW" value="245">{countries.getName("GW", i18n.language)} (+245)</option>
          <option data-countrycode="GY" value="592">{countries.getName("GY", i18n.language)} (+592)</option>
          <option data-countrycode="HT" value="509">{countries.getName("HT", i18n.language)} (+509)</option>
          <option data-countrycode="HN" value="504">{countries.getName("HN", i18n.language)} (+504)</option>
          <option data-countrycode="HK" value="852">{countries.getName("HK", i18n.language)} (+852)</option>
          <option data-countrycode="HU" value="36">{countries.getName("HU", i18n.language)} (+36)</option>
          <option data-countrycode="IS" value="354">{countries.getName("IS", i18n.language)} (+354)</option>
          <option data-countrycode="IN" value="91">{countries.getName("IN", i18n.language)} (+91)</option>
          <option data-countrycode="ID" value="62">{countries.getName("ID", i18n.language)} (+62)</option>
          <option data-countrycode="IR" value="98">{countries.getName("IR", i18n.language)} (+98)</option>
          <option data-countrycode="IQ" value="964">{countries.getName("IQ", i18n.language)} (+964)</option>
          <option data-countrycode="IE" value="353">{countries.getName("IE", i18n.language)} (+353)</option>
          <option data-countrycode="IL" value="972">{countries.getName("IL", i18n.language)} (+972)</option>
          <option data-countrycode="IT" value="39">{countries.getName("IT", i18n.language)} (+39)</option>
          <option data-countrycode="JM" value="1876">{countries.getName("JM", i18n.language)} (+1876)</option>
          <option data-countrycode="JP" value="81">{countries.getName("JP", i18n.language)} (+81)</option>
          <option data-countrycode="JO" value="962">{countries.getName("JO", i18n.language)} (+962)</option>
          <option data-countrycode="KZ" value="7">{countries.getName("KZ", i18n.language)} (+7)</option>
          <option data-countrycode="KE" value="254">{countries.getName("KE", i18n.language)} (+254)</option>
          <option data-countrycode="KI" value="686">{countries.getName("KI", i18n.language)} (+686)</option>
          <option data-countrycode="KP" value="850">{countries.getName("KP", i18n.language)} (+850)</option>
          <option data-countrycode="KR" value="82">{countries.getName("KR", i18n.language)} (+82)</option>
          <option data-countrycode="KW" value="965">{countries.getName("KW", i18n.language)} (+965)</option>
          <option data-countrycode="KG" value="996">{countries.getName("KG", i18n.language)} (+996)</option>
          <option data-countrycode="LA" value="856">{countries.getName("LA", i18n.language)} (+856)</option>
          <option data-countrycode="LV" value="371">{countries.getName("LV", i18n.language)} (+371)</option>
          <option data-countrycode="LB" value="961">{countries.getName("LB", i18n.language)} (+961)</option>
          <option data-countrycode="LS" value="266">{countries.getName("LS", i18n.language)} (+266)</option>
          <option data-countrycode="LR" value="231">{countries.getName("LR", i18n.language)} (+231)</option>
          <option data-countrycode="LY" value="218">{countries.getName("LY", i18n.language)} (+218)</option>
          <option data-countrycode="LI" value="417">{countries.getName("LI", i18n.language)} (+417)</option>
          <option data-countrycode="LT" value="370">{countries.getName("LT", i18n.language)} (+370)</option>
          <option data-countrycode="LU" value="352">{countries.getName("LU", i18n.language)} (+352)</option>
          <option data-countrycode="MO" value="853">{countries.getName("MO", i18n.language)} (+853)</option>
          <option data-countrycode="MK" value="389">{countries.getName("MK", i18n.language)} (+389)</option>
          <option data-countrycode="MG" value="261">{countries.getName("MG", i18n.language)} (+261)</option>
          <option data-countrycode="MW" value="265">{countries.getName("MW", i18n.language)} (+265)</option>
          <option data-countrycode="MY" value="60">{countries.getName("MY", i18n.language)} (+60)</option>
          <option data-countrycode="MV" value="960">{countries.getName("MV", i18n.language)} (+960)</option>
          <option data-countrycode="ML" value="223">{countries.getName("ML", i18n.language)} (+223)</option>
          <option data-countrycode="MT" value="356">{countries.getName("MT", i18n.language)} (+356)</option>
          <option data-countrycode="MH" value="692">{countries.getName("MH", i18n.language)} (+692)</option>
          <option data-countrycode="MQ" value="596">{countries.getName("MQ", i18n.language)} (+596)</option>
          <option data-countrycode="MR" value="222">{countries.getName("MR", i18n.language)} (+222)</option>
          <option data-countrycode="YT" value="269">{countries.getName("YT", i18n.language)} (+269)</option>
          <option data-countrycode="MX" value="52">{countries.getName("MX", i18n.language)} (+52)</option>
          <option data-countrycode="FM" value="691">{countries.getName("FM", i18n.language)} (+691)</option>
          <option data-countrycode="MD" value="373">{countries.getName("MD", i18n.language)} (+373)</option>
          <option data-countrycode="MC" value="377">{countries.getName("MC", i18n.language)} (+377)</option>
          <option data-countrycode="MN" value="976">{countries.getName("MN", i18n.language)} (+976)</option>
          <option data-countrycode="MS" value="1664">{countries.getName("MS", i18n.language)} (+1664)</option>
          <option data-countrycode="MA" value="212">{countries.getName("MA", i18n.language)} (+212)</option>
          <option data-countrycode="MZ" value="258">{countries.getName("MZ", i18n.language)} (+258)</option>
          <option data-countrycode="MN" value="95">{countries.getName("MN", i18n.language)} (+95)</option>
          <option data-countrycode="NA" value="264">{countries.getName("NA", i18n.language)} (+264)</option>
          <option data-countrycode="NR" value="674">{countries.getName("NR", i18n.language)} (+674)</option>
          <option data-countrycode="NP" value="977">{countries.getName("NP", i18n.language)} (+977)</option>
          <option data-countrycode="NL" value="31">{countries.getName("NL", i18n.language)} (+31)</option>
          <option data-countrycode="NC" value="687">{countries.getName("NC", i18n.language)} (+687)</option>
          <option data-countrycode="NZ" value="64">{countries.getName("NZ", i18n.language)} (+64)</option>
          <option data-countrycode="NI" value="505">{countries.getName("NI", i18n.language)} (+505)</option>
          <option data-countrycode="NE" value="227">{countries.getName("NE", i18n.language)} (+227)</option>
          <option data-countrycode="NG" value="234">{countries.getName("NG", i18n.language)} (+234)</option>
          <option data-countrycode="NU" value="683">{countries.getName("NU", i18n.language)} (+683)</option>
          <option data-countrycode="NF" value="672">{countries.getName("NF", i18n.language)} (+672)</option>
          <option data-countrycode="NP" value="670">{countries.getName("NP", i18n.language)} (+670)</option>
          <option data-countrycode="NO" value="47">{countries.getName("NO", i18n.language)} (+47)</option>
          <option data-countrycode="OM" value="968">{countries.getName("OM", i18n.language)} (+968)</option>
          <option data-countrycode="PK" value="92">{countries.getName("PK", i18n.language)} (+92)</option>
          <option data-countrycode="PW" value="680">{countries.getName("PW", i18n.language)} (+680)</option>
          <option data-countrycode="PA" value="507">{countries.getName("PA", i18n.language)} (+507)</option>
          <option data-countrycode="PG" value="675">{countries.getName("PG", i18n.language)} (+675)</option>
          <option data-countrycode="PY" value="595">{countries.getName("PY", i18n.language)} (+595)</option>
          <option data-countrycode="PE" value="51">{countries.getName("PE", i18n.language)} (+51)</option>
          <option data-countrycode="PH" value="63">{countries.getName("PH", i18n.language)} (+63)</option>
          <option data-countrycode="PL" value="48">{countries.getName("PL", i18n.language)} (+48)</option>
          <option data-countrycode="PT" value="351">{countries.getName("PT", i18n.language)} (+351)</option>
          <option data-countrycode="PR" value="1787">{countries.getName("PR", i18n.language)} (+1787)</option>
          <option data-countrycode="QA" value="974">{countries.getName("QA", i18n.language)} (+974)</option>
          <option data-countrycode="RE" value="262">{countries.getName("RE", i18n.language)} (+262)</option>
          <option data-countrycode="RO" value="40">{countries.getName("RO", i18n.language)} (+40)</option>
          <option data-countrycode="RU" value="7">{countries.getName("RU", i18n.language)} (+7)</option>
          {/* <!--<option data-countrycode="RU" value="7">Russia (+7)</option>--> */}
          <option data-countrycode="RW" value="250">{countries.getName("RW", i18n.language)} (+250)</option>
          <option data-countrycode="SM" value="378">{countries.getName("SM", i18n.language)} (+378)</option>
          <option data-countrycode="ST" value="239">{countries.getName("ST", i18n.language)} (+239)</option>
          <option data-countrycode="SA" value="966">{countries.getName("SA", i18n.language)}(+966)</option>
          <option data-countrycode="SN" value="221">{countries.getName("SN", i18n.language)} (+221)</option>
          <option data-countrycode="CS" value="381">{countries.getName("RS", i18n.language)} (+381)</option>
          <option data-countrycode="SC" value="248">{countries.getName("SC", i18n.language)} (+248)</option>
          <option data-countrycode="SL" value="232">{countries.getName("SL", i18n.language)} (+232)</option>
          <option data-countrycode="SG" value="65">{countries.getName("SG", i18n.language)} (+65)</option>
          <option data-countrycode="SK" value="421">{countries.getName("SK", i18n.language)} (+421)</option>
          <option data-countrycode="SI" value="386">{countries.getName("SI", i18n.language)} (+386)</option>
          <option data-countrycode="SB" value="677">{countries.getName("SB", i18n.language)} (+677)</option>
          <option data-countrycode="SO" value="252">{countries.getName("SO", i18n.language)} (+252)</option>
          <option data-countrycode="ZA" value="27">{countries.getName("ZA", i18n.language)} (+27)</option>
          <option data-countrycode="ES" value="34">{countries.getName("ES", i18n.language)} (+34)</option>
          <option data-countrycode="LK" value="94">{countries.getName("LK", i18n.language)} (+94)</option>
          <option data-countrycode="SH" value="290">{countries.getName("SH", i18n.language)} (+290)</option>
          <option data-countrycode="KN" value="1869">{countries.getName("KN", i18n.language)} (+1869)</option>
          <option data-countrycode="SC" value="1758">{countries.getName("SC", i18n.language)} (+1758)</option>
          <option data-countrycode="SD" value="249">{countries.getName("SD", i18n.language)} (+249)</option>
          <option data-countrycode="SR" value="597">{countries.getName("SR", i18n.language)} (+597)</option>
          <option data-countrycode="SZ" value="268">{countries.getName("SZ", i18n.language)} (+268)</option>
          <option data-countrycode="SE" value="46">{countries.getName("SE", i18n.language)} (+46)</option>
          <option data-countrycode="CH" value="41">{countries.getName("CH", i18n.language)} (+41)</option>
          <option data-countrycode="SI" value="963">{countries.getName("SI", i18n.language)} (+963)</option>
          <option data-countrycode="TW" value="886">{countries.getName("TW", i18n.language)} (+886)</option>
          <option data-countrycode="TJ" value="992">{countries.getName("TJ", i18n.language)} (+992)</option>
          <option data-countrycode="TH" value="66">{countries.getName("TH", i18n.language)} (+66)</option>
          <option data-countrycode="TG" value="228">{countries.getName("TG", i18n.language)} (+228)</option>
          <option data-countrycode="TO" value="676">{countries.getName("TO", i18n.language)} (+676)</option>
          <option data-countrycode="TT" value="1868">{countries.getName("TT", i18n.language)}(+1868)</option>
          <option data-countrycode="TN" value="216">{countries.getName("TN", i18n.language)} (+216)</option>
          <option data-countrycode="TR" value="90">{countries.getName("TR", i18n.language)} (+90)</option>
          <option data-countrycode="TM" value="993">{countries.getName("TM", i18n.language)} (+993)</option>
          <option data-countrycode="TC" value="1649">{countries.getName("TC", i18n.language)} (+1649)</option>
          <option data-countrycode="TV" value="688">{countries.getName("TV", i18n.language)} (+688)</option>
          <option data-countrycode="UG" value="256">{countries.getName("UG", i18n.language)} (+256)</option>
          <option data-countrycode="GB" value="44" >{countries.getName("GB", i18n.language)} (+44)</option>
          {/* <!-- <option data-countrycode="GB" value="44">UK (+44)</option> --> */}
          <option data-countrycode="UA" value="380">{countries.getName("UA", i18n.language)} (+380)</option>
          <option data-countrycode="AE" value="971">{countries.getName("AE", i18n.language)}(+971)</option>
          <option data-countrycode="UY" value="598">{countries.getName("UY", i18n.language)} (+598)</option>
          <option data-countrycode="UZ" value="998">{countries.getName("UZ", i18n.language)} (+998)</option>
          <option data-countrycode="US" value="1">{countries.getName("US", i18n.language)} (+1)</option>
          <option data-countrycode="UZ" value="7">{countries.getName("UZ", i18n.language)} (+7)</option>
          <option data-countrycode="VU" value="678">{countries.getName("VU", i18n.language)} (+678)</option>
          <option data-countrycode="VA" value="379">{countries.getName("VA", i18n.language)} (+379)</option>
          <option data-countrycode="VE" value="58">{countries.getName("VE", i18n.language)} (+58)</option>
          <option data-countrycode="VN" value="84">{countries.getName("VN", i18n.language)} (+84)</option>
          <option data-countrycode="VG" value="1">{countries.getName("VG", i18n.language)} (+1)</option>
          <option data-countrycode="VI" value="1">{countries.getName("VI", i18n.language)} (+1)</option>
          <option data-countrycode="WF" value="681">{countries.getName("WF", i18n.language)} (+681)</option>
          <option data-countrycode="YE" value="969">{countries.getName("YE", i18n.language)} (+969)</option>
          <option data-countrycode="ZM" value="260">{countries.getName("ZM", i18n.language)} (+260)</option>
          <option data-countrycode="ZW" value="263">{countries.getName("ZW", i18n.language)} (+263)</option>
        </optgroup>
      </select>

    );

  }

  handleCountryCodeChange = (e) => {
    this.props.onSelectCountryCode(e.target.value);
  }

}

export default withTranslation()(CountryCode);