import { React, useState } from "react";
import { withTranslation, Trans } from "react-i18next";
import {
  Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup,
  InputGroup, InputGroupText, Input, Button, Col, Row, Alert, Label
} from "reactstrap";
import withOTP from "../HOCs/withOTP";
import DigitInput from "react-digit-input";
import { VIEW_ERROR, VIEW_CATALOGUE } from "../constants/views.js";
import Countdown from "react-countdown";
import CountryCode from "./CountryCode";
import { nsEvenly, nsVL } from "../util/Util.js";
import { fetch } from '../util/Fetch';

const otpDigitClass = "form-control inputs text-center";
const PINCODE_LENGTH = 6;
const RESEND_WAITING_TIME = 30 // seconds
const AUTH_MOB = "/auth";
const AUTH_PIN = "/auth/pin";
const BASKET_ADD = "/basket/add";

function UserAuthModal(props) {
  const [countryCode, setCountryCode] = useState("30"); // Default
  const [invalidCountryCode, setInvalidCountryCode] = useState(false);
  const [mobile, setMobile] = useState("");
  const [invalidMobile, setInvalidMobile] = useState(false);
  const [showMobileModal, setShowMobileModal] = useState(true);
  const [mobileSent, setMobileSent] = useState("");
  const [requestDeletion, setRequestDeletion] = useState(false);
  const [tooManyRequests, setTooManyRequests] = useState(false);
  const validateMobile = (value) => /^\d{10}$/.test(value.replace(/-| /g, ''));

  const [pincode, setPincode] = useState("");
  const [showOTPModal, setShowOTPModal] = useState(false);
  const [showResentCode, setShowResentCode] = useState(false);
  const [enableResendButton, setEnableResendButton] = useState(false);
  const [showResendMsg, setShowResendMsg] = useState(false);
  const [pinExpired, setPinExpired] = useState(false);
  const [pinInvalid, setPinInvalid] = useState(false);
  const invalidPincode = pincode.replace(/ /g, '').length !== PINCODE_LENGTH;

  const [showConsentModal, setShowConsentModal] = useState(false);
  const [processConsent1, setProcessConsent1] = useState(null);
  const [processConsent2, setProcessConsent2] = useState(null);
  const [token, setToken] = useState("");
  const [nextPressed, setNextPressed] = useState(false);

  const { t, productless } = props;
  const ns = productless ? nsEvenly : nsVL;

  const alert = getAlert();

  const disabledSendNewCode = showResentCode ? !enableResendButton : showResentCode && !mobileSent;

  const mobileModalBody =
    <div id="selection-method">
      <ModalBody>
        <Form onSubmit={e => e.preventDefault()}>
          <div id="form-mobile">
            <p>{t('cusAuthModal.mobileDescr')}</p>
            <FormGroup>
              <CountryCode onSelectCountryCode={handleCountryCode} defaultValue={countryCode} invalid={invalidCountryCode} />
              {invalidCountryCode && <div className="invalid-feedback" style={{ width: "100%", display: "inline" }}>{t('cusAuthModal.countryCodeInvalid')}</div>}
            </FormGroup>
            <FormGroup>
              <InputGroup>
                <div className="input-group-prepend">
                  <InputGroupText>+{countryCode}</InputGroupText>
                </div>
                <Input type="tel" className="form-control" id="mobileNumber" placeholder={t('cusAuthModal.mobilePlaceholder')} value={mobile} onChange={(e) => setMobile(e.target.value)} onBlur={() => setInvalidMobile(!validateMobile(mobile))} required invalid={invalidMobile} />
                {invalidMobile && mobile !== "" && <div className="invalid-feedback" style={{ width: "100%", display: "inline" }}>{t('cusAuthModal.mobileInvalid')}</div>}
                {invalidMobile && mobile === "" && <div className="invalid-feedback" style={{ width: "100%", display: "inline" }}>{t('cusAuthModal.required')}</div>}
                {requestDeletion && <Alert color="info" className="mt-4 mb-0"><strong>{t('alert.info.title')}</strong>: {t('cusAuthModal.requestDeletion')}</Alert>}
                {tooManyRequests && <Alert color="danger" className="mt-4 mb-0"><strong>{t('alert.danger.title')}</strong>: {t('errorList.tooManyRequests')}</Alert>}
              </InputGroup>
            </FormGroup>
          </div>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button className="w-100 p-2" color="primary" onClick={next} disabled={nextPressed}>{t('cusAuthModal.nextBtn')}</Button>
      </ModalFooter>
    </div >;

  const otpModalBody =
    <div id="pin-input">
      <ModalBody>
        <FormGroup>
          <Label tag="h6" className="text-muted">{t('cusAuthModal.otpDescr', { number: PINCODE_LENGTH, mob_num: mobile })}</Label>
        </FormGroup>
        <DigitInput
          acceptedCharacters={/^[0-9]$/}
          length={PINCODE_LENGTH}
          value={pincode}
          onChange={(value) => setPincode(value)}
        >
          {props => (
            <Row className="mb-2">
              <Col className="pr-0">
                <Input className={otpDigitClass} placeholder="*" type="tel" {...props[0]} onKeyPress={submitWithEnter} />
              </Col>
              <Col className="pr-0">
                <Input className={otpDigitClass} placeholder="*" type="tel" {...props[1]} onKeyPress={submitWithEnter} />
              </Col>
              <Col className="pr-0">
                <Input className={otpDigitClass} placeholder="*" type="tel" {...props[2]} onKeyPress={submitWithEnter} />
              </Col>
              <Col className="pr-0">
                <Input className={otpDigitClass} placeholder="*" type="tel" {...props[3]} onKeyPress={submitWithEnter} />
              </Col>
              <Col className="pr-0">
                <Input className={otpDigitClass} placeholder="*" type="tel" {...props[4]} onKeyPress={submitWithEnter} />
              </Col>
              <Col>
                <Input className={otpDigitClass} placeholder="*" type="tel" {...props[5]} onKeyPress={submitWithEnter} />
              </Col>
            </Row>
          )}
        </DigitInput>
        {alert}
        <div className="mt-4">
          <div>
            <span>{t('cusAuthModal.notReceivedOtp.title')}</span>&nbsp;
            <Button className="mb-1 text-link" color="link" onClick={handleSendNewCode} disabled={disabledSendNewCode}>{t('cusAuthModal.notReceivedOtp.sendBtn')}</Button>
          </div>
        </div>
        {showResentCode && !enableResendButton && <div id="resend">
          <div id="timer-msg" className="small text-danger">
            <i className="far fa-clock mr-2" />
            <Countdown
              date={Date.now() + RESEND_WAITING_TIME * 1000}
              renderer={props => <span id="timer">{t('cusAuthModal.notReceivedOtp.descr', { time: enableResendButton ? 0 : props.seconds })}</span>}
              onComplete={() => setEnableResendButton(true)} />
          </div>
        </div>}
        {showResendMsg && <div id="code-sent-msg" className="small text-info2">
          <i className="fas fa-exclamation-circle mr-2" />{t('cusAuthModal.notReceivedOtp.sent')}
        </div>}
      </ModalBody>
      <ModalFooter>
        <Button className="btn-center" color="outline-dark" onClick={goBackOTP}>{t('cusAuthModal.backBtn')}</Button>
        <Button className="btn-center" color="primary" disabled={invalidPincode || nextPressed} onClick={verifyOTP}>{t('cusAuthModal.continueBtn')}</Button>
      </ModalFooter>
    </div>;

  const consentModalBody =
    <div id="consent">
      <ModalBody>
        <FormGroup>
          <Label tag="h5">{t('cusAuthModal.consent.descr')}</Label>
        </FormGroup>
        <FormGroup>
          <div className="custom-control custom-checkbox mb-2">
            <Input type="checkbox" className="custom-control-input" id="consent-process1" defaultChecked={processConsent1} onChange={(e) => setProcessConsent1(e.target.checked)} />
            <Label className="custom-control-label" for="consent-process1"><Trans ns={ns.ns} i18nKey="cusAuthModal.consent.checkbox1">Sample idx 0<a className="text-link clickable" onClick={(e) => props.handleLegal('privacy', e)}>Sample idx1</a></Trans></Label>
            {processConsent1 === false && <div className="invalid-feedback" style={{ width: "100%", display: "inline-block" }}>{t('cusAuthModal.consent.required')}</div>}
          </div>
          <div className="custom-control custom-checkbox">
            <Input type="checkbox" className="custom-control-input" id="consent-process2" defaultChecked={processConsent2} onChange={(e) => setProcessConsent2(e.target.checked)} />
            <Label className="custom-control-label" for="consent-process2"><Trans ns={ns.ns} i18nKey="cusAuthModal.consent.checkbox2">Sample idx 0<a className="text-link clickable" onClick={(e) => props.handleLegal('tnc', e)}>Sample idx1</a></Trans></Label>
            {processConsent2 === false && <div className="invalid-feedback" style={{ width: "100%", display: "inline-block" }}>{t('cusAuthModal.consent.required')}</div>}
          </div>
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button className="btn-center" color="outline-dark" onClick={goBackConsent}>{t('cusAuthModal.backBtn')}</Button>
        <Button className="btn-center" color="primary" disabled={invalidPincode} onClick={() => completeUserAuth()}>{t('cusAuthModal.continueBtn')}</Button>
      </ModalFooter>
    </div>;

  return (
    <div id="modal-content">
      <Modal zIndex={"auto"} centered={true} isOpen={props.isOpen} toggle={props.onClose}>
        <ModalHeader className="border-0 h-0" toggle={props.onClose}>
        </ModalHeader>
        {showMobileModal && mobileModalBody}
        {showOTPModal && otpModalBody}
        {showConsentModal && consentModalBody}
      </Modal>
    </div>
  );

  function handleCountryCode(val) {
    setCountryCode(val);
    setInvalidCountryCode(!val);
  }

  function next() {
    setInvalidCountryCode(!countryCode);
    if (!countryCode)
      return;
    setInvalidMobile(!validateMobile(mobile))
    if (!validateMobile(mobile))
      return;
    requestOTP();
  }

  function goBackOTP() {
    setNextPressed(false);
    setShowMobileModal(true);
    setShowOTPModal(false);
    setShowResentCode(false);
    setEnableResendButton(false);
    setPinExpired(false);
    setPinInvalid(false);
    setRequestDeletion(false);
    setTooManyRequests(false);
  }

  function goBackConsent() {
    setNextPressed(false);
    setShowConsentModal(false);
    setShowOTPModal(true);
  }

  function submitWithEnter(e) {
    if (e.key === "Enter" && pincode.replace(/ /g, '').length === PINCODE_LENGTH)
      verifyOTP();
  }

  function requestOTP() {
    if (mobileSent === mobile) {
      setShowOTPModal(true);
      setShowMobileModal(false);
      return;
    }

    setNextPressed(true);
    const { api } = props.context;
    fetch(api + AUTH_MOB, {
      method: 'POST',
      body: JSON.stringify({ mobile: countryCode + mobile, lang: props.i18n.language }),
      headers: { 'Content-Type': 'application/json', }
    })
      .then(response => {
        setNextPressed(false);
        if (response.status === 200) {
          setMobileSent(mobile);
          setShowOTPModal(true);
          setShowMobileModal(false);
          return;
        }
        return Promise.reject({ status: response.status, statusText: response.statusText });
      })
      .catch((error) => {
        if (error.status === 400)
          setInvalidMobile(true)
        else if (error.outdated)
          props.openReloadModal()
        else if (error.status === 404)
          setRequestDeletion(true);
        else if (error.status === 429)
          setTooManyRequests(true);
        else
          props.setView(VIEW_ERROR)
      });
  }

  function verifyOTP() {
    setNextPressed(true);
    const { api } = props.context;
    fetch(api + AUTH_PIN, {
      method: 'POST',
      redirect: 'error',
      body: JSON.stringify({ mobile: countryCode + mobile, pin: pincode, lang: props.i18n.language }),
      headers: { 'Content-Type': 'application/json', }
    })
      .then(response => {
        setNextPressed(false);
        if (response.status === 200)
          return response.json();
        return Promise.reject({ status: response.status, statusText: response.statusText });
      }).then(data => {
        if (!data)
          return;
        data.consent = false; // TODO - Save options to db
        if (!data.consent) {
          setShowOTPModal(false);
          setShowConsentModal(true);
          setToken(data.Token);
        } else
          completeUserAuth(data.Token);
      })
      .catch((error) => {
        if (error.status === 400) {
          setPinInvalid(true);
          setPinExpired(false);
        } else if (error.status === 401) {
          setPinInvalid(false);
          setPinExpired(true);
        } else if (error.outdated)
          props.openReloadModal()
        else if (error.status === 429)
          setTooManyRequests(true);
        else
          props.setView(VIEW_ERROR)
      });
  }

  function resendOTP() {
    setEnableResendButton(false);
    setMobileSent(mobile);
    setPincode("");
    setPinExpired(false);
    setPinInvalid(false);
    requestOTP();
    setShowResentCode(false);
  }

  function handleSendNewCode() {
    if (showResentCode) {
      setShowResendMsg(true);
      resendOTP();
    }
    else {
      setShowResendMsg(false);
      setPinExpired(false);
      setPinInvalid(false);
      setShowResentCode(true);
      setMobileSent("");
    }
  }

  function completeUserAuth(tkn = "") {
    if (!processConsent1 || !processConsent2) {
      setProcessConsent1(!!processConsent1);
      setProcessConsent2(!!processConsent2);
      return;
    }
    let usrToken = tkn || token;
    props.setUser(usrToken);
    !props.tabletMode && props.removeVSToken();
    props.nextView && props.setView(props.nextView);
    props.onClose();

    !props.productless && props.bootstrapAuthFetch();
    props.callback ? props.callback() : props.resetProducts();
    if (!!props.prdOnUserAuth && props.prdOnUserAuth.qty)
      saveAnonymous(usrToken);
  }

  function getAlert() {
    const pinInvalidMsg = t('cusAuthModal.otpInvalid');
    const pinExpiredMsg = <div><Trans i18nKey="cusAuthModal.otpExpired">Sample idx 0<a className="text-link clickable" onClick={handleSendNewCode}>Sample idx1</a>Sample idx2</Trans></div>;
    const tooManyRequestsMsg = t('errorList.tooManyRequests');

    let alertMsg;
    if (pinInvalid)
      alertMsg = pinInvalidMsg;
    else if (pinExpired)
      alertMsg = pinExpiredMsg;
    else if (tooManyRequests)
      alertMsg = tooManyRequestsMsg;

    return alertMsg && <Alert color="danger" className="mt-4"><strong>{t('alert.danger.title')}</strong>: {alertMsg}</Alert>
  }

  function saveAnonymous(tkn) {
    let langParam = "lang=" + props.i18n.language, pua = props.prdOnUserAuth;
    const { api } = props.context;
    fetch(api + BASKET_ADD + "?" + langParam, {
      method: 'POST',
      body: JSON.stringify({ qty: pua.qty, prd_id: pua.prd_id, venue_id: props.venueID, offer_id: pua.po_dtls?.offer_id }),
      headers: { 'Authorization': "Bearer " + tkn, 'Content-Type': 'application/json' }
    })
      .then(response => {
        if (response.status === 200)
          return response.json();
        return Promise.reject({ status: response.status, statusText: response.statusText })
      })
      .then(data => {
        window.dataLayer.push({
          event: 'addToCart',
        });
        if (!data)
          return;
        props.setBasketCount(data.basket);
        props.setPIRNotice(data.pir_notice);
      })
      .catch((error) => {
        if (error.status === 409)
          props.nextView !== VIEW_CATALOGUE && props.showSavedPrdModalHandler(true);
        else if (error.outdated)
          props.openReloadModal();
        else
          props.setView(VIEW_ERROR);
      });
  }
}

export default withOTP(withTranslation()(UserAuthModal));