import React from 'react';
import ConfigContext from '../contexts/ConfigContext';

export default function withConfigContext(Component) {
  return function WrapperComponent(props) {
    return (
      <ConfigContext.Consumer>
        {config => <Component {...props} context={config} />}
      </ConfigContext.Consumer>
    );
  };
}
