import React, { Suspense, Component } from 'react';
import Navigation from './components/Navigation';
import Footer from './components/Footer';
import TimeOutModal from './components/TimeOutModal';
import { fetch } from './util/Fetch';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Alert } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { withCookies } from 'react-cookie';
import jwtDecode from 'jwt-decode';
import * as _ from 'lodash';
import Countdown from 'react-countdown';
import i18n from 'i18next';
import FooterLogo from './components/FooterUmbrella';
import ConfigContext from './contexts/ConfigContext';
import CookieConsentBanner from './components/CookieConsentBanner';
import InstantCus from './components/call/InstantCus';
import InstantChat from './components/call/InstantChat';
import CusAuthModal from './components/CusAuthModal';
import { changeFavicon, changeMetaTags, compareLangLabel, evenlyLogo, isWorkingHour, loadCustomStyle, nsEvenly, nsVL, removeURLParameter, vlLogo } from './util/Util';
import { clearStorageItem } from './util/Storage.js';
import { STORAGE_VENUE_FRIENDLY_URL, STORAGE_VENUE_ID } from './constants/storage';

// Constants
import { BARCODE_FORMAT_EAN_13, BARCODE_FORMAT_QR } from './constants/barcode.js'
import {
  VIEW_HOME, VIEW_SCANNER, VIEW_CATALOGUE, VIEW_WISHLIST, VIEW_PRODUCT,
  VIEW_BASKET, VIEW_ORDERS, VIEW_ABOUT, VIEW_TNC, VIEW_SETTINGS, VIEW_ERROR,
  VIEW_CHECKOUT, VIEW_ORDER_REVIEW,
  VIEW_BOOKING, VIEW_BOOKING_BCC, VIEW_MANAGE_BOOKING, VIEW_USER_HOME, VIEW_PVSFINISH, HOMEPAGE, VIEW_VENUEHOME,
  VIEW_BOOKING_HOME, VIEW_VIDEOCALL, VIEW_VIDEOCALL_END, VIEW_VIDEOCALL_QNR, VIEW_TABLET_STANDBY, VIEW_NO_RETAILER,
  VIEW_EVENT_VIEWER, VIEW_EVENT_HOST, VIEW_LEGAL, VIEW_IMM_MODE, VIEW_IMM_MODE_TTS, VIEW_IN_PERSON_MODE, VIEW_IN_PERSON_MODE_PURE, VIEW_CHAT_HISTORY
} from './constants/views.js'

// Styles
import './styles/custom.scss';

import classnames from 'classnames';

import { parseScannedUrl, checkQRParams, extract1DBarcode } from './util/Scan.js';
import { SOURCE_CAMERA } from './constants/source';
import TagManager from 'react-gtm-module';
import Legal from './components/Legal';
import ChatHistory from './components/ChatHistory.js';
import AudioOnlyRequest from './components/call/AudioOnlyRequest.js';

// Initialise GTM
const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_ID,
  auth: process.env.REACT_APP_GTM_AUTH,
  preview: process.env.REACT_APP_GTM_PREVIEW
};
tagManagerArgs.gtmId && TagManager.initialize(tagManagerArgs);

// Code splitting
const Home = React.lazy(() => import('./components/Home'));
const VenueHome = React.lazy(() => import('./components/VenueHome'));
const Scanner = React.lazy(() => import('./components/Scanner'));
const Catalogue = React.lazy(() => import('./components/CatalogueList'));
const Wishlist = React.lazy(() => import('./components/Wishlist'));
const Product = React.lazy(() => import('./components/Product'));
const Basket = React.lazy(() => import('./components/Basket'));
const OrderList = React.lazy(() => import('./components/OrderList'));
const TNC = React.lazy(() => import('./components/TNC'));
const Settings = React.lazy(() => import('./components/Settings'));
const Error = React.lazy(() => import('./components/Error'));
const Checkout = React.lazy(() => import('./components/Checkout'));
const OrderReview = React.lazy(() => import('./components/OrderReview'));
const PIR = React.lazy(() => import('./components/PIR'));

const Booking = React.lazy(() => import('./components/Booking'));
const BookingBCC = React.lazy(() => import('./components/BookingBCC'));
const BookingManage = React.lazy(() => import('./components/BookingManage'));
const BookingHome = React.lazy(() => import('./components/BookingHome'));
const UserHome = React.lazy(() => import('./components/UserHome'));
const PVSFinish = React.lazy(() => import('./components/PVSFinish'));
const Call = React.lazy(() => import('./components/call/Call'));
const CallEnd = React.lazy(() => import('./components/call/CallEnd'));
const CallQnr = React.lazy(() => import('./components/call/CallQnr'));
const TabletStandby = React.lazy(() => import('./components/call/TabletStandby'));
const NoRetailer = React.lazy(() => import('./components/NoRetailer'));

const EventViewer = React.lazy(() => import('./components/event/Viewer'));
const EventHost = React.lazy(() => import('./components/event/Host'));
const EventInPersonMode = React.lazy(() => import('./components/event/InPersonMode'))
const EventInPersonModePure = React.lazy(() => import('./components/event/InPersonModePure'))

const ImmigrationMode = React.lazy(() => import('./components/demo/ImmigrationMode'));

const BOOTSTRAP = '/bootstrap';
const BOOTSTRAP_AUTH = '/bootstrap/auth';
const LINK = '/link';
const USER_JOINED = '/user/meeting/join';
const CUS_JOINED = '/meeting/join';
const EB2C_JOINED = '/eb2c/meeting/join';
const EB2C_SESSION_INV = '/eb2c/invitation';
const LOGOUT = '/logout'
const securedViews = [VIEW_BASKET, VIEW_ORDERS, VIEW_SETTINGS, VIEW_WISHLIST,
  VIEW_VIDEOCALL, VIEW_MANAGE_BOOKING, VIEW_BOOKING, VIEW_BOOKING_BCC, VIEW_EVENT_VIEWER, VIEW_CHAT_HISTORY];
const scannerExcludedViews = [
  VIEW_ERROR, VIEW_BOOKING, VIEW_BOOKING_BCC, VIEW_BOOKING_HOME, VIEW_VIDEOCALL_END];
const footerLogoLessViews = [
  VIEW_ERROR, VIEW_SCANNER, VIEW_VIDEOCALL, VIEW_VIDEOCALL_END, VIEW_EVENT_VIEWER,
  VIEW_EVENT_HOST, VIEW_LEGAL, VIEW_IMM_MODE, VIEW_IMM_MODE_TTS, VIEW_IN_PERSON_MODE, VIEW_IN_PERSON_MODE_PURE];
const videoCallViews = [
  VIEW_VIDEOCALL, VIEW_VIDEOCALL_END, VIEW_EVENT_VIEWER, VIEW_EVENT_HOST, VIEW_IMM_MODE, VIEW_IMM_MODE_TTS,
  VIEW_IN_PERSON_MODE, VIEW_IN_PERSON_MODE_PURE];
const skipContextResetViews = [VIEW_USER_HOME, VIEW_BOOKING_HOME, VIEW_LEGAL];
const navigationLessViews = [VIEW_LEGAL];

const evenlyViews = [
  VIEW_BOOKING_HOME, VIEW_BOOKING, VIEW_BOOKING_BCC, VIEW_MANAGE_BOOKING,
  VIEW_USER_HOME, VIEW_PVSFINISH, VIEW_TABLET_STANDBY,
  VIEW_VIDEOCALL, VIEW_VIDEOCALL_END, VIEW_VIDEOCALL_QNR,
  VIEW_EVENT_VIEWER, VIEW_EVENT_HOST, VIEW_IN_PERSON_MODE,
  VIEW_ERROR, VIEW_SETTINGS, VIEW_LEGAL,
  VIEW_IMM_MODE, VIEW_IMM_MODE_TTS, VIEW_IN_PERSON_MODE_PURE, VIEW_CHAT_HISTORY,
];

const evenlyB2CViews = [
  VIEW_VIDEOCALL, VIEW_VIDEOCALL_END
];

const legalTypes = ["privacy", "tnc", "cookies"];

const DEFAULT_CURRENCY = "EUR";
const TOKEN_ADMIN_NAME = "token-admin"
const TOKEN_CUS_NAME = "token-cus";
const AGENT_NAME = "agent-name";
const IS_INTERPRETER = "is-interpreter";
const EVENLY = "evenly";
const EVENLYB2C = "evenly-b2c";
const supportedLanguages = ['en', 'el', 'ar', 'nl', 'ro'];

class App extends Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();

    this.throttled = _.throttle(this.setCataloguePosition, 1000).bind(this)

    const statisticsCookie = !!this.props.cookies.get("cns_statistics", { doNotParse: false })

    //this.updateGTMConsent(statisticsCookie)
    let gtm_consent = statisticsCookie ? 'granted' : 'denied'
    window.gtag('consent', 'update', {
      'ad_storage': gtm_consent,
      'analytics_storage': gtm_consent
    })

    this.state = {
      config: {
        api: this.getAPIEndpoint(),
        penman: this.getPenmanEndpoint(),
        penman_trans: this.getPenmanTransEndpoint(),
        license: {},
      },
      tokenName: {
        admin: TOKEN_ADMIN_NAME,
        cus: TOKEN_CUS_NAME
      },
      agentName: AGENT_NAME,
      nextView: null,
      previousView: "",
      currentView: null,
      // homepage: "",
      furl: "",
      position: 0,
      products_checkout: [],
      showNavMenu: false,
      backButton: false,
      ret_details: {
        show_pay_method: false,
        show_eta: false,
        checkout_limit: 4,
        min_order: 40,
        def_lang: "en",
        langs: [],
        currency: DEFAULT_CURRENCY,
        license: {
          wishlist: false,
          instant: {},
        },
        service: "",
        name: "Retailer",
        phone: "XXXXXXXXXX",
        legals: {},
      },
      venue_details: {
        venue: "",
        venue_tpe: "",
        hideCatalogue: false
      },
      basketCount: 0,
      showAlert: false,
      expired: [],
      fullscreenViews: ['home', 'error', 'aroffer'],
      prdOnUserAuth: null,
      purl: "",
      showIncompatibleQRModal: false,
      showExceededPrdModal: false,
      showSavedPrdModal: false,
      showReloadModal: false,
      serverError: false,
      orderRef: "",
      orderRefCompleted: null,
      vhrAvailable: false,
      loadingBS: true,
      loadingLnk: true,
      showCookiesOptionsModal: false,
      statisticsCookie: statisticsCookie,
      products: {
        list: [],
        count: 0,
        offset: 0,
        maxPrice: 0,
      },
      ars_mode: false,
      mtngRef: null, // If set, customer has joined meeting
      meetingID: null,
      customerID: null,
      leaveMeeting: false,
      endMeeting: () => { },
      sendChatMessage: () => { },
      videoCallFullscreen: true,
      currentMeetingDetails: {},
      isInterpreter: false,
      isInterpreterName: IS_INTERPRETER,
      showInstantCallRequestModal: false,
      showInstantChatRequestModal: false,
      showAudioOnlyRequestModal: false,
      instantCallPopup: false,
      instantVenue: null,
      showUserAuthModal: false,
      userAuthCallback: () => { },
      showCallDetailsModal: false,
      showCallTroubleshootingModal: false,
      notificationSound: false,
      showWorkingHoursModal: false,
      instantServiceRequested: "",
      hideNavControls: false
    }

    // initialize local store currency value
    localStorage.setItem('currency', DEFAULT_CURRENCY);

    window.onpopstate = (e) => {
      document.activeElement.blur();

      if (e.state?.currView === VIEW_CATALOGUE)
        sessionStorage.setItem("filters", JSON.stringify(e.state.filters || {}))

      this.setState({ backButton: true }, e.state && this.setView(e.state.currView));
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.ret_details !== prevProps.ret_details) {
      this.bootstrapFetch();
    }

    const PIRNotice = this.state.PIRNotice
    const PrevPIRNotice = prevState.PIRNotice
    const prevShowPIR = prevState.showPIR

    if (
      //spotlight just reached
      (!PrevPIRNotice && PIRNotice && !prevShowPIR) ||
      //t2 just reached
      (PIRNotice && PIRNotice.bskt_amnt >= PIRNotice.pir.t2 && PrevPIRNotice.bskt_amnt < PIRNotice.pir.t2 && !prevShowPIR) ||
      //t1 just reached
      (PIRNotice && PIRNotice.bskt_amnt >= PIRNotice.pir.t1 && PrevPIRNotice.bskt_amnt < PIRNotice.pir.t1 && !prevShowPIR)
    )
      this.setState({ showPIR: true })

  }

  componentDidMount() {
    this.bootstrapFetch(this.linkFetch, this.bootstrapAuthFetch);
  }

  linkFetch = () => {
    const { ret_details } = this.state;
    const ctxPath = ret_details.with_ctx_path ? window.location.pathname.split("/")[1] : (process.env.PUBLIC_URL.split("/")[1] || "")
    // Remove venue from cookies - Quick fix
    if (ret_details.license.product === EVENLY)
      this.props.cookies.remove("venue");
    const venue = this.props.cookies.get("venue")
    var path = window.location.pathname.split("/")
    path.shift() //removing first empty string element

    if (path.length > 0 && path[0].toLowerCase() === ctxPath.toLowerCase())
      path.shift()
    const path3 = path.length > 2 ? path[2].toLowerCase() : ""
    const path2 = path.length > 1 ? path[1].toLowerCase() : ""
    const path1 = (path.shift() || "").toLowerCase()
    let mtngRef

    var furl, legalType, legalAnchor
    var view = this.state.homepage
    let instantChatPopup = false, instantCallPopup = false

    if (path1 === "basket") {
      let pmtFailed = this.getQueryParam("pmt_failed")
      if (pmtFailed)
        this.setState({ pmtFailed: true })
      view = VIEW_BASKET
    } else if (path1 === "pvs") {
      view = VIEW_USER_HOME
    } else if (path1 === "error") {
      this.setState({
        errorType: this.getQueryParam("type")
      })
      view = VIEW_ERROR
    } else if (path1 === "order") {
      let ref = this.getQueryParam("ref")
      let completed = this.getQueryParam("completed")

      if (ref === undefined)
        view = VIEW_BASKET
      else {
        this.setState({
          orderRef: ref,
          orderRefCompleted: completed === "true" && ref
        })
        view = VIEW_ORDER_REVIEW
      }
    } else if (["live", "broadcast"].includes(path1) && ret_details.license.live_broadcast_cc) {
      if (path1 === "live")
        view = VIEW_EVENT_VIEWER
      else if (path1 === "broadcast")
        view = VIEW_EVENT_HOST
    } else if (path1 === "chat" && ret_details.license.instant?.chat) {
      instantChatPopup = true
    } else if (path1 === "instant" && ret_details.license.instant?.remote) {
      instantCallPopup = true
    } else if (path1.startsWith("book") && ret_details.license.booking) {
      let view_booking = ret_details.bcc ? VIEW_BOOKING_BCC : VIEW_BOOKING
      if (path1 === "book")
        view = path2 === "book-step-1" ? view_booking : VIEW_BOOKING_HOME
      else if (path1 === "book-step-1")
        view = view_booking
      else if (path1 === "book-manage")
        view = VIEW_MANAGE_BOOKING
    } else if (path1 === "chat-history") {
      view = VIEW_CHAT_HISTORY
      let ref = this.getQueryParam("ref")
      this.setState({
        mtngRef: ref,
      }, () => this.setView(view))
    } else if (/^\/b\/\d{1,5}$/.test(path)) {
      this.setState({
        brandID: parseInt(path.substr(3))
      })
      view = VIEW_CATALOGUE
    } else if (path1 === "mtng" && ret_details.license.product !== EVENLYB2C) {
      let ref = this.getQueryParam("ref")

      if (ref === undefined)
        view = this.state.homepage
      else {
        this.setState({
          mtngRef: ref,
          isInterpreter: this.getQueryParam("i") === "1",
          cusJoined: true // TODO: review if needed
        }, () => {
          view = VIEW_VIDEOCALL
          this.setView(view)
        })
        mtngRef = true
      }
    } else if (path1 === "session" && ret_details.license.product === EVENLYB2C) {
      let inv = this.getQueryParam("inv")
      if (inv) {
        console.log("inv", inv)
        this.sessionInvitationLookup(inv)
        return
      }

      let ref = this.getQueryParam("id")

      if (ref === undefined)
        view = this.state.homepage
      else {
        const isEvenlyB2CHost = false;
        const isEvenlyB2CFriend = this.getQueryParam("f") === "1";
        const isEvenlyB2CParticipant = !isEvenlyB2CFriend;
        this.setState({
          mtngRef: ref,
          isEvenlyB2CHost: isEvenlyB2CHost,
          isEvenlyB2CParticipant: isEvenlyB2CParticipant,
          isEvenlyB2CFriend: isEvenlyB2CFriend
        }, () => {
          view = VIEW_VIDEOCALL
          this.setView(view)
        })
        mtngRef = true
      }
      // Check license
    } else if (path1 === "mtng-qnr" && ret_details.license.cus_qnr) {
      let ref = this.getQueryParam("ref")

      if (ref === undefined)
        view = this.state.homepage
      else {
        this.setState({
          mtngRef: ref
        }, () => {
          view = VIEW_VIDEOCALL_QNR
          this.setView(view)
        })
        mtngRef = true
      }
    } else if (path1 === "legal" && legalTypes.includes(path2)) {
      view = VIEW_LEGAL
      legalType = path2
      legalAnchor = window.location.hash.substring(1);
    } else {
      furl = path1.split("?")[0]
    }

    let tabletMode = false;
    let audioOnlyMode = false;
    let agentID;

    var params = ""
    if (furl && /^\w{3,10}$/.test(furl)) {
      params = "?furl=" + furl
      // Check instant call popup and tablet mode scenarios through link
      instantCallPopup = ret_details.license.instant?.instore_cus && this.getQueryParam("callpopup") === "true"
      tabletMode = ret_details.license.instant !== null && this.getQueryParam("tablet") === "true"
      // Audio-only mode
      audioOnlyMode = ret_details.license.on_site_audio_only && this.getQueryParam("audio_only") === "true"
      instantCallPopup = instantCallPopup || audioOnlyMode
      agentID = instantCallPopup && this.getQueryParam("id")
    } else if (venue) {
      params = "?venue=" + venue
    }
    const externalLanding = instantCallPopup || tabletMode

    fetch(this.state.config.api + LINK + params, {
      method: 'GET',
      headers: {
        'Authorization': "Bearer " + this.getToken(),
      }
    })
      .then(response => {
        if (response.status === 200)
          return response.json();
        else
          return Promise.reject({ status: response.status, statusText: response.statusText, appBC: response.headers.get('App-BC') })
      })
      .then(data => {
        if (data) {
          this.props.cookies.set("venue", data.venue_id, { maxAge: 60 * 60 * 24, path: "/", sameSite: "Lax", secure: document.location.protocol.includes("https") });

          let venueScanned = false
          if (furl) {
            view = data.link_tp

            // if a venue QR code is scanned from the in app scanner when you are already inside a store, show a modal without changing the view
            // if (data.no_catalogue && this.state.currentView===VIEW_SCANNER && this.state.venue_details.hideCatalogue){
            //   view = VIEW_SCANNER
            //   venueScanned = true
            // }
          }
          const workingHour = isWorkingHour(ret_details.wrk_hrs);

          this.setState({
            venue_details: {
              venue: data.venue_id,
              venue_def: data.venue_def,
              venue_tpe: data.venue_tp,
              hideCatalogue: data.no_catalogue
            },
            furl: furl,
            format: BARCODE_FORMAT_QR,
            source: SOURCE_CAMERA,
            loadingLnk: false,
            // homepage: data.no_catalogue ? VIEW_VENUEHOME : this.state.homepage,
            instantCallPopup: instantCallPopup,
            instantChatPopup: instantChatPopup,
            audioOnlyMode: audioOnlyMode,
            agentID: agentID,
            legalType: legalType,
            legalAnchor: legalAnchor,
            tabletMode: tabletMode,
            // Init PIR when scanning an instant call QR Code
            showPIR: externalLanding ? false : this.state.showPIR,
            venueScanned: venueScanned
          }, () => {
            var vsToken = localStorage.getItem(this.state.tokenName.admin)
            // Handle booking subpaths
            if (path2 === "book") {
              view = path3 === "book-step-1" ? VIEW_BOOKING : VIEW_BOOKING_HOME
              this.setView(view)
            } else if (path2 === "book-step-1")
              this.setView(VIEW_BOOKING)
            else if (!vsToken || view !== HOMEPAGE || tabletMode) {
              if (instantCallPopup) {
                if (workingHour === false)
                  this.handleShowWorkingHoursModal("go live now");
                else if (this.getToken())
                  this.setShowInstantCallRequestModal();
                else
                  this.handleShowUserAuthModal(this.setShowInstantCallRequestModal);
              } else if (instantChatPopup) {
                if (workingHour === false)
                  this.handleShowWorkingHoursModal("live chat");
                else if (this.getToken())
                  this.setShowInstantChatRequestModal();
                else
                  this.handleShowUserAuthModal(this.setShowInstantChatRequestModal);
              } else {
                if (tabletMode) {
                  view = VIEW_TABLET_STANDBY
                }
                this.setView(view)
              }
            }
          })
        }
      })
      .catch((error) => {
        if (error.timeout)
          this.setState({ serverError: true });
        else if (error.status === 401) {
          this.logout();
        } else if (error.outdated) {
          this.setRetDetails({}, this.bootstrap)
          this.handleOpenReloadModal()
        } else {
          this.setView(VIEW_ERROR);
        }
      });

    // TODO: Review Valuelenz in multi-tenant mode
    // Retailer resolution affects the functionality
    let historyEntryURL = process.env.PUBLIC_URL || window.location.origin + "/" + ctxPath;
    // Retain subpath
    const isEvenlyB2C = ret_details.license.product === EVENLYB2C;
    if ((skipContextResetViews.indexOf(view) >= 0 || mtngRef || isEvenlyB2C) && !instantChatPopup && !instantCallPopup)
      historyEntryURL = null

    // Remove lang query param from URL
    if (this.getQueryParam("lang"))
      historyEntryURL = removeURLParameter(window.location.href, "lang");

    window.history.replaceState({ currView: view }, "", historyEntryURL);
  }



  bootstrapAuthFetch = () => {

    // Set token; remove old token if expired
    const token = this.getToken();
    if (token) {
      if (jwtDecode(token).exp < Date.now() / 1000) {
        return this.removeToken()
      } else {
        this.setUser(token);
      }
    } else {
      return this.removeToken()
    }


    fetch(this.state.config.api + BOOTSTRAP_AUTH, {
      method: 'GET',
      headers: {
        'Authorization': "Bearer " + token,
      }
    })
      .then(response => {
        if (response.status === 200)
          return response.json();
        else
          return Promise.reject({ status: response.status, statusText: response.statusText, appBC: response.headers.get('App-BC') })
      })
      .then(data => {
        if (data) {
          this.setState({
            basketCount: data.basket_cnt
          })
          this.setPIRNotice(data.pir_notice)
        }
      })
      .catch((error) => {
        if (error.timeout)
          this.setState({ serverError: true });
        else if (error.status === 401)
          this.logout();
        else if (error.outdated) {
          this.setRetDetails({}, this.bootstrap)
          this.handleOpenReloadModal()
        }
        else
          this.setView(VIEW_ERROR);
      });
  }

  bootstrapFetch = (linkFetch, bootstrapAuthFetch) => {
    fetch(this.state.config.api + BOOTSTRAP, {
      method: 'GET'
    })
      .then(response => {
        if (response.status === 200) {
          this.setStorageItem("session-id", response.headers.get("session-id"))
          return response.json();
        }
        else
          return Promise.reject({ status: response.status, statusText: response.statusText, appBC: response.headers.get('App-BC') })
      })
      .then(data => {
        if (data) {
          const callback = () => {
            this.bootstrap()
            linkFetch()
            data.license_det.product !== EVENLY && bootstrapAuthFetch()
          }
          // Send tenant (friendly) ID to GTM
          window.gtag('set', {
            't_id': data.friendly_id
          });
          this.setRetDetails(data, callback)
        }
      })
      .catch((error) => {
        if (error.timeout)
          this.setState({ serverError: true });
        else if (error.status === 401)
          this.logout();
        else if (error.status === 400 || error.status === 403) {
          this.setView(VIEW_NO_RETAILER);
        }
        else if (error.outdated) {
          this.setRetDetails({}, this.bootstrap)
          this.handleOpenReloadModal()
        }
        else
          this.setView(VIEW_ERROR);
      });
  }

  sessionInvitationLookup = (inv) => {
    console.log("sessionInvitationLookup", inv)
    if (!inv) return
    fetch(this.state.config.api + EB2C_SESSION_INV + '?id=' + inv, {
      method: 'GET'
    })
      .then(response => {
        console.log("sessionInvitationLookup response", response)
        if (response.status === 200) {
          // console.log("sessionInvitationLookup response 200 .json", response.json())
          return response.json();
        }
      })
      .then(data => {
        console.log("sessionInvitationLookup data url:", data.url)
        if (data) {
          window.location.href = data.url
        }
      })
      .catch((error) => {
        if (error.timeout)
          this.setState({ serverError: true });
        else
          this.setView(VIEW_ERROR);
      });
  }

  setRetDetails = (ret_details, callback) => {
    const currState = this.state.ret_details
    const langs = (ret_details.langs || currState.langs).filter(lang => supportedLanguages.includes(lang))
    const retName = ret_details.name || currState.name
    const defaultLogo = [EVENLY, EVENLYB2C].includes(ret_details.license_det?.product) ? evenlyLogo : vlLogo
    const newState = {
      langs: langs,
      def_lang: ret_details.def_lang || currState.def_lang,
      currency: ret_details.currency || currState.currency,
      logo: ret_details.logo || defaultLogo,
      favicon: ret_details.favicon,
      name: retName,
      show_pay_method: ret_details.show_pay_method || currState.show_pay_method,
      show_eta: ret_details.show_eta || currState.show_eta,
      checkout_limit: ret_details.checkout_limit || currState.checkout_limit,
      shp_thres: ret_details.shp_thres,
      min_order: ret_details.min_order || currState.min_order,
      license: ret_details.license_det || currState.license,
      variants_ui: ret_details.variants_ui,
      shp_cost: ret_details.shp_cost,
      rating: ret_details.rating,
      alert: ret_details.alert,
      aggr_id: ret_details.aggr_id,
      extra_fields: ret_details.extra_fields,
      friendly_id: ret_details.friendly_id,
      cc_lang: ret_details.cc_lang,
      cc_langs: ret_details.cc_langs,
      show_interpreter_notice: ret_details.show_interpreter_notice,
      custom_booking_home: ret_details.custom_booking_home,
      custom_style: ret_details.custom_style,
      service: ret_details.service || currState.service,
      bcc: ret_details.bcc,
      with_ctx_path: ret_details.with_ctx_path,
      wrk_hrs: ret_details.wrk_hrs,
      legals: ret_details.legals,
      phone: ret_details.phone || currState.phone,
      instant_instore_def_rules: ret_details.instant_instore_def_rules,
    }
    this.setState(() => ({
      ret_details: newState,
      showAlert: ret_details.alert !== undefined,
      config: {
        ...this.state.config,
        license: newState.license,
        wasp_url: ret_details.wasp_url
      },
      tokenName: {
        admin: `${TOKEN_ADMIN_NAME}-${(retName.toLowerCase()).replace(/\s+/g, '-')}`,
        cus: `${TOKEN_CUS_NAME}-${ret_details.aggr_id}`
      },
      agentName: `${AGENT_NAME}-${(retName.toLowerCase()).replace(/\s+/g, '-')}`,
      isInterpreterName: `${IS_INTERPRETER}-${(retName.toLowerCase()).replace(/\s+/g, '-')}`,
      loadingBS: false,
      homepage: newState.license.product === EVENLY ? VIEW_BOOKING_HOME : newState.license.product === EVENLYB2C ? null : VIEW_CATALOGUE
    }), callback)
  }

  bootstrap = () => {
    const ret_details = this.state.ret_details;
    const isEvenly = [EVENLY, EVENLYB2C].includes(ret_details.license.product);
    sessionStorage.removeItem("VScusDtls"); // temp fix of resetting VScusDtls with every reload
    sessionStorage.removeItem("dummyParticipants");

    // Set the language
    i18n.changeLanguage(this.getPreferredLanguage());
    this.sortCCLangs();

    // Set the currency
    localStorage.setItem('currency', ret_details.currency);

    // Set the product name
    localStorage.setItem('product', isEvenly ? nsEvenly.ns : nsVL.ns);

    // DEMO: Do not display warning for already saved offers
    if (process.env.REACT_APP_DEMO === 'true') {
      localStorage.setItem(this.state.tokenName.cus, process.env.REACT_APP_DEMO_TOKEN);
    }

    // load custom style
    ret_details.custom_style && loadCustomStyle(ret_details.friendly_id);

    // Set page title and favicon
    changePageTitle(ret_details.name, isEvenly, ret_details.license.custom_dom_nme)
    changeFavicon(ret_details.favicon, isEvenly)

    // Change meta tags based on product type
    changeMetaTags(this.props.t, isEvenly);

    // Virtual Shopping
    var vsToken = localStorage.getItem(this.state.tokenName.admin)
    var agentName = localStorage.getItem(this.state.agentName)
    var isInterpreter = localStorage.getItem(this.state.isInterpreterName) === "true"

    if (vsToken) {
      var jwt = jwtDecode(vsToken)
      if (jwt.exp <= Date.now() / 1000) {
        localStorage.removeItem(this.state.tokenName.admin);
        sessionStorage.removeItem("VScusDtls");
        this.removeToken()
        vsToken = null
      } else {
        this.setVSUser({ vsToken: vsToken, vsUsrNme: agentName, isInterpreter: isInterpreter })
        this.setView(VIEW_USER_HOME);
        var VScusDtls = JSON.parse(sessionStorage.getItem("VScusDtls") || "{}")
        if (VScusDtls.tel)
          this.setVSCusDtls(VScusDtls.tel, VScusDtls.email, VScusDtls.mtng_id, VScusDtls.name, VScusDtls.postcode)
      }
      return
    }

    let view = this.state.homepage
    if (isEvenly)
      view = VIEW_BOOKING_HOME
    this.setView(view)
  }

  render() {
    const { t } = this.props;
    const { ret_details, venue_details, venueScanned, anchor, PIRNotice, showPIR, mtngRef, vsdk_mtng_id, videoCallFullscreen, userJoined, cusJoined, ars_mode, showInstantCallRequestModal, instantCallPopup, showUserAuthModal, nextView, instantVenue, tabletMode, showInstantChatRequestModal, instantChatPopup, showAudioOnlyRequestModal } = this.state;
    const agentName = this.state.vsUsrNme || localStorage.getItem(this.state.agentName);
    const productless = [EVENLY, EVENLYB2C].includes(ret_details.license.product);

    const isEvenlyB2C = ret_details.license.product === EVENLYB2C;

    let isAgent = !!this.state.vsToken && !this.state.isInterpreter;
    let isCustomer = !!this.getToken();
    let isInterpreter = this.state.isInterpreter;

    if (isEvenlyB2C) {
      isAgent = this.state.isEvenlyB2CHost; // Host
      isCustomer = this.state.isEvenlyB2CParticipant; // Participant
      isInterpreter = this.state.isEvenlyB2CFriend; // Friends & Family (possibly interpreter)
    }

    const alrt =
      this.state.showAlert &&
      <Alert className="alert bg-ribbon-color text-white my-3" toggle={() => this.setState({ showAlert: false })}>
        {ret_details.alert.alert_msg[this.props.i18n.language] || ret_details.alert.alert_msg[this.props.i18n.languages[1]]}
      </Alert>

    const retailerAlert =
      this.state.showAlert &&
      <Countdown
        onComplete={() => this.setState({ showAlert: false })}
        date={Date.now() + ret_details.alert.alert_period}
        renderer={() => { return alrt }}
      />

    const instantCallCusRequest =
      <InstantCus
        user={this.getToken()}
        api={this.state.config.api}
        setView={this.setView}
        openReloadModal={this.openReloadModal}
        logout={this.logout}
        setMtngID={this.setMtngID}
        instant={ret_details.license.instant}
        cc={ret_details.license.cc && {
          defLang: ret_details.cc_lang,
          langs: ret_details.cc_langs
        }}
        interpreter={ret_details.license.interpreter}
        showInterpreterNotice={ret_details.show_interpreter_notice}
        venue={instantVenue || venue_details.venue}
        instore={this.state.venue_details.venue_tpe === 'physical_store'}
        visible={showInstantCallRequestModal}
        setVisible={this.setShowInstantCallRequestModal}
        tabletMode={tabletMode}
        isTouchpoint={!venue_details.venue_def}
        phone={ret_details.phone}
        rules={ret_details.instant_instore_def_rules}
        audioOnlyMode={this.state.audioOnlyMode}
        agentID={this.state.agentID}
      />;

    const instantChatRequest =
      <InstantChat
        user={this.getToken()}
        api={this.state.config.api}
        setView={this.setView}
        openReloadModal={this.openReloadModal}
        logout={this.logout}
        setMtngID={this.setMtngID}
        instant={ret_details.license.instant}
        venue={venue_details.venue}
        visible={showInstantChatRequestModal}
        setVisible={this.setShowInstantChatRequestModal}
        extraFields={ret_details.extra_fields?.chat}
        phone={ret_details.phone}
        bcc={ret_details.bcc}
      />;

    const audioOnlyRequest =
      <AudioOnlyRequest
        token={this.state.vsToken}
        api={this.state.config.api}
        setView={this.setView}
        openReloadModal={this.openReloadModal}
        logout={this.logout}
        setMtngID={this.setMtngID}
        cc={ret_details.license.cc && {
          defLang: ret_details.cc_lang,
          langs: ret_details.cc_langs
        }}
        venue={instantVenue || venue_details.venue}
        visible={showAudioOnlyRequestModal}
        setVisible={this.setShowAudioOnlyRequestModal}
      />;

    const navigation = <Navigation
      user={this.getToken()}
      logout={this.logout}
      logoutUser={this.logoutUser}
      showNavMenu={this.state.showNavMenu}
      toggleNavMenu={this.toggleNavMenu}
      onNavigate={this.setView}
      onBackBtn={this.setBackBtn}
      clearSearch={this.state.clearSearch}
      clearSearchFlag={this.clearSearchFlag}
      currentView={this.state.currentView}
      licenseDtls={{
        wishlist: ret_details.license.wishlist,
        logo: ret_details.logo,
        langs: ret_details.langs,
        hasCusReg: ret_details.license.cus_reg,
        retailer: ret_details.name,
        booking: ret_details.license.booking,
        instant: ret_details.license.instant?.remote,
        instantChat: ret_details.license.instant?.chat,
        bcc: ret_details.bcc,
        broadcast: ret_details.license.live_broadcast_cc,
        in_person_mode: ret_details.license.in_person_mode,
        chatDownload: ret_details.license.chat_download,
        audioOnlyMode: ret_details.license.on_site_audio_only,
      }}
      openReloadModal={this.handleOpenReloadModal}
      basketCount={this.state.basketCount}
      updateCatFromPrdView={this.updateCatFromPrdView}
      venue={venue_details.venue}
      hideSearch={venue_details.hideCatalogue || productless}
      pir={this.state.PIRNotice}
      clickPIR={this.state.clickPIR}
      openPIR={() => this.setState({ showPIR: true })}
      // cusJoined is used to hide content - TODO: rename prop 
      cusJoined={cusJoined || [VIEW_VIDEOCALL_END, VIEW_VIDEOCALL_QNR].includes(this.state.currentView)}
      agentJoined={this.state.userJoined && isAgent}
      isAgent={isAgent}
      isVideoCall={this.state.currentView === VIEW_VIDEOCALL}
      productless={productless}
      hideCatalogue={venue_details.hideCatalogue}
      arsMode={ars_mode}
      createBooking={this.state.createBooking}
      setInitBookingForm={(value) => this.setState({ initBookingForm: value })}
      toggleShowCallDetailsModal={this.toggleShowCallDetailsModal}
      toggleShowCallTroubleshootingModal={this.toggleShowCallTroubleshootingModal}
      isAdminUser={!!this.state.vsToken}
      VScusDtls={this.state.VScusDtls}
      removeToken={this.removeToken}
      resetVScusDtls={this.resetVScusDtls}
      clearProductOffers={this.clearProductOffers}
      clearProducts={this.clearProducts}
      removeVSToken={this.removeVSToken}
      setView={this.setView}
      adminUsrNme={agentName}
      basket={!!this.state.basketCount}
      vsdkMtngID={vsdk_mtng_id}
      endMeeting={this.state.endMeeting}
      maximiseVideoCall={this.maximiseVideoCall}
      sendChatMessage={this.state.sendChatMessage}
      setShowInstantCallRequestModal={this.setShowInstantCallRequestModal}
      handleShowUserAuthModal={this.handleShowUserAuthModal}
      vsToken={this.state.vsToken}
      customerID={this.state.customerID}
      meetingID={this.state.meetingID}
      setShowInstantChatRequestModal={this.setShowInstantChatRequestModal}
      isWorkingHour={isWorkingHour(ret_details.wrk_hrs)}
      handleShowWorkingHoursModal={this.handleShowWorkingHoursModal}
      hideNavControls={this.state.hideNavControls}
      sortCCLangs={this.sortCCLangs}
      setShowAudioOnlyRequestModal={this.setShowAudioOnlyRequestModal}
    />;

    const home = <Home
      setView={this.setView}
      stylingClass='home' />;

    const venueHome = <VenueHome
      setView={this.setView}
    />;

    const bookingHome = <BookingHome
      setView={this.setView}
      productless={productless}
      bcc={ret_details.bcc}
      ret_friendly_id={ret_details.custom_booking_home && ret_details.friendly_id}
      interpreter={ret_details.license.interpreter}
      booking={ret_details.license.booking}
      instant={ret_details.license.instant?.remote}
      user={this.getToken()}
      setShowInstantCallRequestModal={this.setShowInstantCallRequestModal}
      handleShowUserAuthModal={this.handleShowUserAuthModal}
      instantChat={ret_details.license.instant?.chat}
      setShowInstantChatRequestModal={this.setShowInstantChatRequestModal}
    />;

    const scanner = <Scanner
      user={this.getToken()}
      setView={this.setView}
      setScanned={this.setScanned}
      from={this.state.previousView}
    />;

    const product = <Product
      showExceededPrdModalHandler={(value) => { this.setState({ showExceededPrdModal: value }) }}
      showSavedPrdModalHandler={(value) => { this.setState({ showSavedPrdModal: value }) }}
      showExceededPrdModal={this.state.showExceededPrdModal}
      showSavedPrdModal={this.state.showSavedPrdModal}
      setBasketCount={this.setBasketCount}
      setBreadcrumbFromPrdView={this.setBreadcrumbFromPrdView}
      user={this.getToken()}
      from={this.state.previousView}
      setView={this.setView}
      source={this.state.source}
      setPrevView={this.setPrevView}
      furl={this.state.furl}
      barcode={this.state.barcode}
      format={this.state.format}
      hideCatPath={venue_details.hideCatalogue}
      licenseDtls={{
        poffers: ret_details.license.poffers,
        retailer: ret_details.name,
        wishlist: ret_details.license.wishlist,
        hasCusReg: ret_details.license.cus_reg,
      }}
      variantsUI={ret_details.variants_ui}
      showRating={ret_details.rating}
      setPrdUserAuthDetails={(prd) => this.setState({ prdOnUserAuth: prd })}
      products={this.state.products}
      setProducts={this.setProducts}
      venue={venue_details.venue}
      mtngID={this.state.VScusDtls && this.state.VScusDtls.mtng_id}
      logout={this.logout}
      openReloadModal={this.handleOpenReloadModal}
      setPIRNotice={this.setPIRNotice}
      handleShowUserAuthModal={this.handleShowUserAuthModal}
      prdOnUserAuth={this.state.prdOnUserAuth}
      vsToken={this.state.vsToken}
      customerID={this.state.customerID}
    />;

    const basket = <Basket
      setBasketCount={this.setBasketCount}
      user={this.getToken()}
      view='saved'
      setView={this.setView}
      onCheckout={this.checkout}
      VScusDtls={this.state.VScusDtls}
      resetVScusDtls={this.resetVScusDtls}
      removeToken={this.removeToken}
      clearProducts={this.clearProducts}
      clearProductOffers={this.clearProductOffers}
      vs={this.state.virtual_shopping}
      products={this.state.products}
      setProducts={this.setProducts}
      logout={this.logout}
      logoutUser={this.logoutUser}
      openReloadModal={this.handleOpenReloadModal}
      setPIRNotice={this.setPIRNotice}
      PIRNotice={this.state.PIRNotice}
      pmtFailed={this.state.pmtFailed}
      dismissPmtFailed={() => this.setState({ pmtFailed: false })}
      setProductFURL={this.setProductFURL}
      setPVS={(status) => this.setState({ pvsBasket: status })}
      vsdkMtngID={vsdk_mtng_id}
      endMeeting={this.state.endMeeting}
      maximiseVideoCall={this.maximiseVideoCall}
      sendChatMessage={this.state.sendChatMessage}
      vsToken={this.state.vsToken}
      customerID={this.state.customerID}
      meetingID={this.state.meetingID}
      directCall={this.state.directCall}
    />;

    const orders = <OrderList
      user={this.getToken()}
      view={VIEW_ORDERS}
      setView={this.setView}
      setOrderRef={this.setOrderRef}
      logout={this.logout}
      openReloadModal={this.handleOpenReloadModal} />;


    const tnc = <TNC
      anchor={anchor}
      friendlyID={ret_details.friendly_id}
      setView={this.setView}
    />

    const settings = <Settings
      user={this.getToken()}
      setView={this.setView}
      logout={this.logout}
      openReloadModal={this.handleOpenReloadModal}
      productless={productless}
    />;

    const error = <Error
      setView={this.setView}
      stylingClass='error' />

    const wishlist = <Wishlist
      setOfferDetails={this.setOfferDetails}
      setProductFURL={this.setProductFURL}
      offers={this.state.offers}
      setPrevView={this.setPrevView}
      user={this.getToken()}
      setView={this.setView}
      logout={this.logout}
      openReloadModal={this.handleOpenReloadModal}
    />;

    const catalogue = <Catalogue
      setProductFURL={this.setProductFURL}
      onBackBtn={this.setBackBtn}
      clearSearchFlag={this.clearSearchFlag}
      prdViewBreadcrumb={this.state.prdViewBreadcrumb}
      backButton={this.state.backButton}
      licenseDtls={{
        stock: ret_details.license.stock,
        wishlist: ret_details.license.wishlist,
        poffers: ret_details.license.poffers,
        hasCusReg: ret_details.license.cus_reg,
        logo: ret_details.logo,
        retailer: ret_details.name
      }}
      prdOnUserAuth={this.state.prdOnUserAuth}
      setPrdUserAuthDetails={(prd) => this.setState({ prdOnUserAuth: prd })}
      updatePrdsFlag={this.state.updatePrdsFlag}
      updateCataloguePrds={this.updateCataloguePrds}
      position={this.state.position}
      products={this.state.products}
      setProducts={this.setProducts}
      setBasketCount={this.setBasketCount}
      setCataloguePosition={this.setCataloguePosition}
      setCataloguePositionThrottled={this.throttled}
      setPrevView={this.setPrevView}
      user={this.getToken()}
      setView={this.setView}
      logout={this.logout}
      venue={venue_details.venue}
      resetVenue={this.resetVenue}
      openReloadModal={this.handleOpenReloadModal}
      brandID={this.state.brandID}
      setPIRNotice={this.setPIRNotice}
      handleShowUserAuthModal={this.handleShowUserAuthModal}
      vsToken={this.state.vsToken}
      customerID={this.state.customerID}
    />;

    const cusAuthModal = <CusAuthModal
      isOpen={showUserAuthModal}
      onClose={this.handleCloseUserAuthModal}
      setUser={this.setUser}
      removeVSToken={this.removeVSToken}
      openReloadModal={this.handleOpenReloadModal}
      setView={this.setView}
      nextView={nextView}
      prdOnUserAuth={this.state.prdOnUserAuth}
      showSavedPrdModalHandler={(value) => { this.setState({ showSavedPrdModal: value }) }}
      setBasketCount={this.setBasketCount}
      setPIRNotice={this.setPIRNotice}
      bootstrapAuthFetch={this.bootstrapAuthFetch}
      resetProducts={this.resetProducts}
      productless={productless}
      venueID={venue_details.venue}
      furl={this.state.furl}
      retailer={ret_details.name}
      callback={this.state.userAuthCallback}
      tabletMode={tabletMode}
      handleLegal={this.handleLegal}
    />;

    const checkout = <Checkout
      user={this.getToken()}
      setView={this.setView}
      products={this.state.products_checkout}
      onBack={this.setView}
      shippingCost={ret_details.shp_cost}
      shippingThres={ret_details.shp_thres}
      updateCataloguePrds={this.updateCataloguePrds}
      completeOrderRef={this.completeOrderRef}
      setBasketCount={this.setBasketCount}
      clearProducts={this.clearProducts}
      logout={this.logout}
      openReloadModal={this.handleOpenReloadModal}
      PIRNotice={this.state.PIRNotice}
      setPIRNotice={this.setPIRNotice}
      setProductFURL={this.setProductFURL}
      isPVS={this.state.pvsBasket}
    />;

    const orderReview = <OrderReview
      user={this.getToken()}
      orderRef={this.state.orderRef}
      setView={this.setView}
      from={VIEW_ORDER_REVIEW}
      onBack={this.setView}
      logout={this.logout}
      openReloadModal={this.handleOpenReloadModal}
      orderRefCompleted={this.state.orderRefCompleted}
      resetOrderCompleted={this.resetOrderCompleted}
      setProductFURL={this.setProductFURL}
    />;

    const userHome = <UserHome
      setVSCusDtls={this.setVSCusDtls}
      setView={this.setView}
      setUser={this.setUser}
      logout={this.logout}
      logoutUser={this.logoutUser}
      openReloadModal={this.handleOpenReloadModal}
      clearProductOffers={this.clearProductOffers}
      ret_id={ret_details.ret_id}
      setVSUser={this.setVSUser}
      vsToken={this.state.vsToken}
      VScusDtls={this.state.VScusDtls}
      removeVSToken={this.removeVSToken}
      mtng_id={this.state.mtng_id}
      clearMtngID={this.clearMtngID}
      cusToken={this.state.user}
      setBasketCount={this.setBasketCount}
      resetProducts={this.resetProducts}
      setCurrentMeetingDetails={this.setCurrentMeetingDetails}
      productless={productless}
      instantARS={ret_details.license.direct}
      isAgent={isAgent}
      isInterpreter={isInterpreter}
      setMtngID={this.setMtngID}
      instant={ret_details.license.instant}
      setCustomerID={this.setCustomerID}
      booking={ret_details.license.booking}
      venue={venue_details.venue}
      notificationSound={this.state.notificationSound}
      setNotificationSound={this.setNotificationSound}
      phone={ret_details.phone}
      bcc={ret_details.bcc}
      audioOnlyMode={ret_details.license.on_site_audio_only}
    />;

    const pvsFinish = <PVSFinish
      resetVScusDtls={this.resetVScusDtls}
      VScusDtls={this.state._VScusDtls}
      removeToken={this.removeToken}
      directCall={this.state.directCall}
      toVSSearchView={() => this.setView(VIEW_USER_HOME)}
    />;

    const booking = <Booking
      venue={venue_details.venue}
      setUser={this.setUser}
      removeVSToken={this.removeVSToken}
      user={this.getToken()}
      setView={this.setView}
      openReloadModal={this.handleOpenReloadModal}
      logout={this.logout}
      interpreter={ret_details.license.interpreter}
      showInterpreterNotice={ret_details.show_interpreter_notice}
      productless={productless}
      setCreateBooking={(value) => this.setState({ createBooking: value })}
      setInitBookingForm={(value) => this.setState({ initBookingForm: value })}
      initBookingForm={this.state.initBookingForm}
      extraFields={ret_details.extra_fields?.booking}
      retailer={ret_details.name}
      cc={ret_details.license.cc && {
        defLang: ret_details.cc_lang,
        langs: ret_details.cc_langs
      }}
      handleLegal={this.handleLegal}
    />;

    const bookingBCC = <BookingBCC
      venue={venue_details.venue}
      setUser={this.setUser}
      removeVSToken={this.removeVSToken}
      user={this.getToken()}
      setView={this.setView}
      openReloadModal={this.handleOpenReloadModal}
      logout={this.logout}
      interpreter={ret_details.license.interpreter}
      showInterpreterNotice={ret_details.show_interpreter_notice}
      productless={productless}
      setCreateBooking={(value) => this.setState({ createBooking: value })}
      setInitBookingForm={(value) => this.setState({ initBookingForm: value })}
      initBookingForm={this.state.initBookingForm}
      extraFields={ret_details.extra_fields?.booking}
      retailer={ret_details.name}
      cc={ret_details.license.cc && {
        defLang: ret_details.cc_lang,
        langs: ret_details.cc_langs
      }}
      handleLegal={this.handleLegal}
    />;

    const bookingManage = <BookingManage
      retailer={ret_details.name}
      productless={productless}
      api={this.state.config.api}
      user={this.getToken()}
      setView={this.setView}
      logout={this.logout}
      openReloadModal={this.handleOpenReloadModal}
      setMtngID={this.setMtngID}
      instant={ret_details.license.instant?.remote}
      setShowInstantCallRequestModal={this.setShowInstantCallRequestModal}
    />;

    const chatHistory = <ChatHistory
      retailer={ret_details.name}
      productless={productless}
      api={this.state.config.api}
      user={this.getToken()}
      setView={this.setView}
      logout={this.logout}
      openReloadModal={this.handleOpenReloadModal}
      setMtngID={this.setMtngID}
      instant={ret_details.license.instant?.remote}
      setShowInstantCallRequestModal={this.setShowInstantCallRequestModal}
      id={this.state.mtngRef}
    />;

    const call = <Call
      api={this.state.config.api}
      cusDetails={{
        token: this.getToken(),
        ...this.state.VScusDtls
      }}
      setCusDetails={this.setVSCusDtls}
      resetCusDetails={this.resetVScusDtls}
      mtngRef={mtngRef}
      meetingID={this.state.meetingID}
      setView={this.setView}
      logout={this.logout}
      logoutUser={this.logoutUser}
      openReloadModal={this.handleOpenReloadModal}
      setEndMeeting={this.setEndMeeting}
      onCusJoined={this.cusJoined}
      onUserJoined={this.userJoined}
      onMeetingEnd={this.setCallEnded}
      fullscreen={videoCallFullscreen}
      toggleVideoCall={this.toggleVideoCall}
      leaveMeeting={this.leaveMeeting}
      hidden={this.state.currentView === VIEW_SCANNER}
      // Extra props for send basket modal
      basket={!!this.state.basketCount}
      removeToken={this.removeToken}
      clearProducts={this.clearProducts}
      setSendChatMessage={this.setSendChatMessage}
      currentMeetingDetails={this.state.currentMeetingDetails}
      productless={productless}
      // Define call roles
      isCustomer={isCustomer}
      isAgent={isAgent && !tabletMode}
      isInterpreter={isInterpreter}
      isEvenlyB2C={isEvenlyB2C}
      toggleShowCallDetailsModal={this.toggleShowCallDetailsModal}
      showCallDetailsModal={this.state.showCallDetailsModal}
      toggleShowCallTroubleshootingModal={this.toggleShowCallTroubleshootingModal}
      showCallTroubleshootingModal={this.state.showCallTroubleshootingModal}
      recording={ret_details.license.recording}
      screenSharing={ret_details.license.screen_sharing}
      subtitlesSave={ret_details.license.subs_extr}
      cc={ret_details.license.cc}
      realTimeTrans={ret_details.license.real_time_trans}
      defCCLang={ret_details.cc_lang}
      ccLangs={ret_details.cc_langs}
      // User details (admin)
      userDetails={!!this.state.vsToken && {
        token: this.state.vsToken,
        name: agentName
      }}
      instantCall={this.state.instantCall}
      docSharing={this.state.ret_details.license.doc_sharing}
      setCustomerID={this.setCustomerID}
      setBasketCount={this.setBasketCount}
      chat={this.state.ret_details.license.chat}
      tabletMode={tabletMode}
      settings={{
        camera: this.getQueryParam("c") === "1" || ret_details.license?.call_preset_cam,
        mic: this.getQueryParam("m") === "1" || ret_details.license?.call_preset_mic
      }}
      onEb2cJoined={this.eb2cJoined}
    />

    const callEnd = <CallEnd
      withBasket={this.state.withBasket}
      toBasketView={() => {
        const wasp_url = process.env.NODE_ENV === 'production' ? this.state.config.wasp_url : window.location.origin;
        window.location.href = wasp_url + "/" + VIEW_BASKET;
      }
      }
      chatDownload={ret_details.license.chat_download}
      user={this.getToken()}
      mtngRef={mtngRef}
      meetingID={this.state.meetingID}
      toErrorView={() => this.setView(VIEW_ERROR)}
      logout={this.logout}
      openReloadModal={this.handleOpenReloadModal}
      qnr={ret_details.license.cus_qnr}
      toQnrView={() => this.setView(VIEW_VIDEOCALL_QNR)}
    />

    const callQnr = <CallQnr
      currentView={this.state.currentView}
      mtngRef={this.state.mtngRef}
      api={this.state.config.api}
      user={this.getToken()}
      toErrorView={() => this.setView(VIEW_ERROR)}
      toBookView={() => this.setView(VIEW_BOOKING)}
      logout={this.logout}
      openReloadModal={this.handleOpenReloadModal}
      retailer={ret_details.name}
      service={ret_details.service}
      handleLegal={this.handleLegal}
    />

    const tabletStandby = <TabletStandby
      user={this.getToken()}
      setShowInstantCallRequestModal={this.setShowInstantCallRequestModal}
      handleShowUserAuthModal={this.handleShowUserAuthModal}
    />

    const liveChannel = "live:" + ret_details.friendly_id;
    const eventViewer = <EventViewer leave={() => this.setView(VIEW_VIDEOCALL_END)} token={this.getToken()} liveChannel={liveChannel} ccLangs={ret_details.cc_langs} />
    const eventHost = <EventHost
      leave={() => this.setView(VIEW_USER_HOME)}
      token={this.state.vsToken}
      liveChannel={liveChannel}
      toErrorView={() => this.setView(VIEW_ERROR)}
      openReloadModal={this.openReloadModal}
      logout={this.logout}
      hideNavControls={() => { this.setState({ hideNavControls: !this.state.hideNavControls }) }}
      ccLangs={ret_details.cc_langs}
    />

    const immigrationMode = <ImmigrationMode
      leaveMeeting={this.leaveMeeting}
      token={this.state.vsToken}
      toErrorView={() => this.setView(VIEW_ERROR)}
      openReloadModal={this.openReloadModal}
      logout={this.logout}
      ccLangs={ret_details.cc_langs}
    />;

    const immigrationModeTTS = <ImmigrationMode
      leaveMeeting={this.leaveMeeting}
      token={this.state.vsToken}
      toErrorView={() => this.setView(VIEW_ERROR)}
      openReloadModal={this.openReloadModal}
      logout={this.logout}
      tts={true}
      ccLangs={ret_details.cc_langs}
    />;

    const eventInPersonMode = <EventInPersonMode
      leaveMeeting={this.leaveMeeting}
      token={this.state.vsToken}
      toErrorView={() => this.setView(VIEW_ERROR)}
      openReloadModal={this.openReloadModal}
      logout={this.logout}
      hideNavControls={() => { this.setState({ hideNavControls: !this.state.hideNavControls }) }}
      ccLangs={ret_details.cc_langs}
    />

    const eventInPersonModePure = <EventInPersonModePure
      leaveMeeting={this.leaveMeeting}
      token={this.state.vsToken}
      toErrorView={() => this.setView(VIEW_ERROR)}
      openReloadModal={this.openReloadModal}
      logout={this.logout}
      hideNavControls={() => { this.setState({ hideNavControls: !this.state.hideNavControls }) }}
      ccLangs={ret_details.cc_langs}
    />

    const lang = i18n.language.split("-")[0] || ret_details.def_lang;

    const legal = <Legal
      product={ret_details.license.product}
      friendlyID={ret_details.friendly_id}
      legalType={this.state.legalType}
      lang={lang}
      legals={ret_details.legals}
      anchor={this.state.legalAnchor}
    />

    // Special case for full screen views.
    const isFullscreen = this.state.fullscreenViews.indexOf(this.state.currentView) !== -1
    const sessionFilters = JSON.parse(sessionStorage.getItem("filters"))
    const mainStyle = sessionFilters && (sessionFilters.catLvl1 && sessionFilters.catLvl1.k1 !== "")
    const mainClasses = classnames('container', { 'h-100': isFullscreen }, { 'pad': this.state.currentView === VIEW_CATALOGUE && !mainStyle }, { 'big-pad': this.state.currentView === VIEW_CATALOGUE && mainStyle })

    const incompatibleQRModal = <Modal centered={true} isOpen={this.state.showIncompatibleQRModal} toggle={this.handleCloseIncompatibleQRModal}>
      <ModalHeader>
        <i className="fas fa-exclamation-triangle fa-sm mr-2"></i>{t('incompatibleQRModal.title', nsVL)}
      </ModalHeader>
      <ModalBody>
        <p className="small">{t('incompatibleQRModal.descr', nsVL)}</p>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={this.handleCloseIncompatibleQRModal}>{t('incompatibleQRModal.confirmBtn', nsVL)}</Button>
      </ModalFooter>
    </Modal>;

    const venueScannedModal =
      <Modal centered={true} isOpen={venueScanned} toggle={this.handleCloseVenueScannedModal}>
        <ModalHeader>
          <i className="fas fa-exclamation-circle fa-sm mr-2"></i>{t('venueScannedModal.title', nsVL)}
        </ModalHeader>
        <ModalBody>
          <p className="small">{t('venueScannedModal.venueScanned', nsVL)}</p>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={this.handleCloseVenueScannedModal}>{t('venueScannedModal.confirmBtn', nsVL)}</Button>
        </ModalFooter>
      </Modal>

    const reloadModal = <Modal centered={true} isOpen={this.state.showReloadModal}>
      <ModalHeader>
        <i className="fas fa-exclamation-triangle fa-sm mr-2"></i>{t('reloadModal.title')}
      </ModalHeader>
      <ModalBody>
        <p className="small">{t('reloadModal.descr')}</p>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={() =>
          window.location.reload(true)}>{t('reloadModal.confirmBtn')}</Button>
      </ModalFooter>
    </Modal>;

    const cookiesOptions = <Modal centered={true} isOpen={this.state.showCookiesOptionsModal} toggle={this.handleToggleCookiesOptionsModal}>
      <ModalHeader toggle={this.handleToggleCookiesOptionsModal}>
        <h5 className="modal-title"><i className="fa fa-cookie-bite fa-sm mr-2"></i>{t('cookiesModal.title')}</h5>
      </ModalHeader>
      <ModalBody>
        <p className="small">{t('cookiesModal.descr')}</p>

        <div className="custom-control custom-checkbox mb-3">
          <input type="checkbox" className="custom-control-input" id="cookies-necessary" checked disabled />
          <label className="custom-control-label small" htmlFor="cookies-necessary">{t('cookiesModal.option1')}</label>
          <small className="form-text text-muted">{t('cookiesModal.option1Msg')}</small>
        </div>

        <div className="custom-control custom-checkbox mb-3">
          <input type="checkbox" className="custom-control-input" id="cookies-statistics" checked={this.state.statisticsCookie} onClick={() => this.setState({ statisticsCookie: !this.state.statisticsCookie })} />
          <label className="custom-control-label small" htmlFor="cookies-statistics">{t('cookiesModal.option2')}</label>
          <small className="form-text text-muted">{t('cookiesModal.option2Msg')}</small>
        </div>
      </ModalBody>
      <ModalFooter>
        <button id="cookieConfirm2" data-dismiss="modal" type="button" className="btn btn-primary" onClick={() => this.handleCookiesConsent()}>{t('cookiesModal.confirmBtn')}</button>
      </ModalFooter>
    </Modal>

    const PIRModal = <PIR
      {...PIRNotice}
      spotlightReached={!!PIRNotice}
      showPIR={showPIR}
      closePIR={() => this.setState({ showPIR: false, clickPIR: true })}
    />

    const workingHoursModal = <Modal centered={true} isOpen={this.state.showWorkingHoursModal} toggle={this.handleCloseWorkingHoursModal}>
      <ModalHeader>
        <i className="fas fa-exclamation-triangle fa-sm mr-2"></i>{t('workingHoursModal.title')}
      </ModalHeader>
      <ModalBody dangerouslySetInnerHTML={{ __html: ret_details.wrk_hrs?.descr[lang].replace("{{service}}", this.state.instantServiceRequested) }} />
      <ModalFooter>
        <Button color="primary" onClick={this.handleCloseWorkingHoursModal}>{t('workingHoursModal.confirmBtn')}</Button>
      </ModalFooter>
    </Modal>;

    const homeView = this.getLandingView()
    const scannerIconSVG = <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" className="mt-2 bi bi-upc-scan" viewBox="0 0 61 61">
      <g xmlns="http://www.w3.org/2000/svg" id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path className="cls-1" d="M23.62,0H9.21A1.75,1.75,0,0,0,7.46,1.75v14.4a1.75,1.75,0,0,0,3.5,0V3.5H23.62a1.75,1.75,0,0,0,0-3.5Z" /><path className="cls-1" d="M23.62,42.4H11V29.75a1.75,1.75,0,0,0-3.5,0v14.4A1.75,1.75,0,0,0,9.21,45.9H23.62a1.75,1.75,0,0,0,0-3.5Z" /><path className="cls-1" d="M51.62,0H37.21a1.75,1.75,0,0,0,0,3.5H49.87V16.15a1.75,1.75,0,0,0,3.5,0V1.75A1.75,1.75,0,0,0,51.62,0Z" /><path className="cls-1" d="M51.62,28a1.75,1.75,0,0,0-1.75,1.75V42.4H37.21a1.75,1.75,0,0,0,0,3.5H51.62a1.75,1.75,0,0,0,1.75-1.75V29.75A1.75,1.75,0,0,0,51.62,28Z" /><path className="cls-1" d="M59.08,24.7H1.75a1.75,1.75,0,0,1,0-3.5H59.08a1.75,1.75,0,0,1,0,3.5Z" /></g></g>
    </svg>
    const scannerIconClose = <i className="fas fa-times-circle mt-2" style={{ "fontSize": "40px" }}></i>
    const scannerIcon = this.state.currentView === VIEW_SCANNER ? scannerIconClose : scannerIconSVG
    const fixedBottomScannerWrap =
      <div className="fixed-bottom d-inline-block d-lg-none">
        <div className="bg-white border-top d-flex justify-content-center">
          <button title="home" type="button" onClick={() => { this.setView(homeView) }} className="btn btn-lg btn-white border-right pb-4 pr-4 shadow-none"><i className="fas fa-lg fa-home  my-2"></i></button>
          <button title="scan" type="button" onClick={() => {
            if (this.state.currentView === VIEW_SCANNER) {
              this.setView(this.state.previousView)
            } else {
              this.setView(VIEW_SCANNER)
            }
          }} className="btn btn-lg btn-white pb-4 px-4 shadow-none">
            {scannerIcon}
          </button>
          <button title="basket" onClick={() => { this.setView(VIEW_BASKET) }} className="btn btn-lg btn-white border-left pb-4 pl-4 fixcart position-relative shadow-none" disabled={!!this.state.vsToken && !this.state.customerID}><i className="fas fa fa-shopping-basket fa-lg"></i>
            <span className="fixcart-basket d-flex align-items-center justify-content-center">{this.state.basketCount}</span>
          </button>
        </div>
      </div>

    if (this.state.currentView === VIEW_NO_RETAILER) {
      document.body.classList.remove("bg-light");
      return suspenseful(<NoRetailer t={this.props.t} />);
    }

    if (!evenlyB2CViews.includes(this.state.currentView) && isEvenlyB2C) {
      return suspenseful(<NoRetailer t={this.props.t} />);
    }

    if (!!this.state.currentView && !evenlyViews.includes(this.state.currentView) && productless) {
      return (
        <ConfigContext.Provider value={this.state.config}>
          {navigation}
          <main role="main" className={mainClasses} id="main">
            {suspenseful(bookingHome)}
          </main>
        </ConfigContext.Provider>
      );
    }

    return (
      // !this.state.loadingBS && !this.state.loadingLnk && <div className="react-div-inline">
      !this.state.loadingBS && !this.state.loadingLnk && <div className="react-div-inline div-rtl" dir={i18n.dir()}>
        <ConfigContext.Provider value={this.state.config}>
          {!navigationLessViews.includes(this.state.currentView) && navigation}
          <main role="main" className={mainClasses} id="main">
            { /* <!-- Alert from retailer --> */}
            {[VIEW_CATALOGUE].includes(this.state.currentView) && retailerAlert}
            { /* <!-- Alert from retailer --> */}
            {this.state.currentView === VIEW_HOME && suspenseful(home)}
            {this.state.currentView === VIEW_VENUEHOME && suspenseful(venueHome)}
            {this.state.currentView === VIEW_SCANNER && suspenseful(scanner)}
            {this.state.currentView === VIEW_CATALOGUE && suspenseful(catalogue)}
            {this.state.currentView === VIEW_WISHLIST && suspenseful(wishlist)}
            {this.state.currentView === VIEW_PRODUCT && (this.state.venue_details.venue || this.props.cookies.get("venue")) && suspenseful(product)}
            {this.state.currentView === VIEW_BASKET && suspenseful(basket)}
            {this.state.currentView === VIEW_ORDERS && suspenseful(orders)}
            {this.state.currentView === VIEW_ABOUT && suspenseful(venueHome)}
            {this.state.currentView === VIEW_TNC && suspenseful(tnc)}
            {this.state.currentView === VIEW_SETTINGS && suspenseful(settings)}
            {this.state.currentView === VIEW_ERROR && suspenseful(error)}
            {/* <! -- User Auth Modal --> */}
            {showUserAuthModal && suspenseful(cusAuthModal)}
            {/* <! -- User Auth Modal --> */}
            {this.state.currentView === VIEW_CHECKOUT && suspenseful(checkout)}
            {this.state.currentView === VIEW_ORDER_REVIEW && suspenseful(orderReview)}
            {this.state.currentView === VIEW_BOOKING && suspenseful(booking)}
            {this.state.currentView === VIEW_BOOKING_BCC && suspenseful(bookingBCC)}
            {this.state.currentView === VIEW_MANAGE_BOOKING && suspenseful(bookingManage)}
            {this.state.currentView === VIEW_BOOKING_HOME && suspenseful(bookingHome)}
            {this.state.currentView === VIEW_USER_HOME && suspenseful(userHome)}
            {this.state.currentView === VIEW_PVSFINISH && suspenseful(pvsFinish)}
            {this.state.currentView === VIEW_VIDEOCALL_END && suspenseful(callEnd)}
            {this.state.currentView === VIEW_VIDEOCALL_QNR && suspenseful(callQnr)}
            {this.state.currentView === VIEW_TABLET_STANDBY && suspenseful(tabletStandby)}
            {/* <!-- Video Call --> */}
            {((this.state.currentView === VIEW_VIDEOCALL) || userJoined) && suspenseful(call)}
            {/* <!-- Video Call End --> */}
            {/* <!-- Live Event --> */}
            {this.state.currentView === VIEW_EVENT_VIEWER && suspenseful(eventViewer)}
            {this.state.currentView === VIEW_EVENT_HOST && suspenseful(eventHost)}
            {this.state.currentView === VIEW_IN_PERSON_MODE && suspenseful(eventInPersonMode)}
            {this.state.currentView === VIEW_IN_PERSON_MODE_PURE && suspenseful(eventInPersonModePure)}
            {/* <!-- Live Event End --> */}
            {/* <!-- Immigration Mode --> */}
            {this.state.currentView === VIEW_IMM_MODE && suspenseful(immigrationMode)}
            {this.state.currentView === VIEW_IMM_MODE_TTS && suspenseful(immigrationModeTTS)}
            {/* <!-- Immigration Mode End --> */}
            {changeBackgroundColor(this.state.currentView)}
            {/* <! -- Modal for Time out fetch --> */}
            {this.state.serverError && <TimeOutModal enableErrorModal={this.callbackCloseModalError} serverError={this.state.serverError} />}
            {/* <! -- Modal for Time out fetch --> */}
            {/* <!-- Legal --> */}
            {this.state.currentView === VIEW_LEGAL && suspenseful(legal)}
            {/* <!-- Legal End --> */}
            {/* <!-- Chat History --> */}
            {this.state.currentView === VIEW_CHAT_HISTORY && suspenseful(chatHistory)}
            {/* <!-- Chat History End --> */}
            {!footerLogoLessViews.includes(this.state.currentView) && <hr className="my-5 my-test" />}
          </main>
          {(showInstantCallRequestModal || instantCallPopup) && this.getToken() && instantCallCusRequest}
          {(showInstantChatRequestModal || instantChatPopup) && this.getToken() && instantChatRequest}
          {showAudioOnlyRequestModal && this.state.vsToken && audioOnlyRequest}
        </ConfigContext.Provider>

        {/* <!-- Umbrella Brand Wrap --> */}
        {!footerLogoLessViews.includes(this.state.currentView) && <FooterLogo logo={ret_details.logo} retailer={ret_details.name} />}
        {/* <!-- Umbrella Brand Wrap End --> */}

        {/* <!-- Fixed Bottom Scanner Wrap --> */}
        {!scannerExcludedViews.includes(this.state.currentView) && !this.state.mtngRef && !productless && (!this.state.instantCall || isAgent) && !this.state.isInterpreter && fixedBottomScannerWrap}
        {/* <!-- Fixed Bottom Scanner Wrap End --> */}

        {!footerLogoLessViews.includes(this.state.currentView) && <Footer productless={productless} setView={this.setView} fullScreen={isFullscreen || this.state.currentView === VIEW_VIDEOCALL} onManageCookies={this.handleToggleCookiesOptionsModal} handleLegal={this.handleLegal} />}

        {/* <! -- Incompatible QR Modal --> */}
        {incompatibleQRModal}
        {/* <! -- Incompatible QR Modal --> */}

        {/* <! -- Reload Modal --> */}
        {reloadModal}
        {/* <! -- Reload Modal --> */}

        {/* <! -- Incompatible QR Modal --> */}
        {venueScannedModal}
        {/* <! -- Incompatible QR Modal --> */}

        {/* <! -- Cookies options Modal --> */}
        {cookiesOptions}
        {/* <! -- Cookies options Modal --> */}

        {showPIR && suspenseful(PIRModal)}

        {/* <! -- Working hours modal --> */}
        {this.state.showWorkingHoursModal && workingHoursModal}
        {/* <! -- Working hours modal --> */}

        {(typeof this.props.cookies.get('cns_necessary') === "undefined") && <CookieConsentBanner
          setView={this.setView}
          onOptions={this.handleToggleCookiesOptionsModal}
          onAccept={() => this.handleCookiesConsent(true)}
          isFullScreen={scannerExcludedViews.includes(this.state.currentView)}
          isEvenly={this.state.ret_details.license.product === EVENLY}
          handleLegal={this.handleLegal}
        />}

      </div>
    );
  }

  toggleVideoCall = () => {
    this.setState({
      videoCallFullscreen: !this.state.videoCallFullscreen
    })
  }
  minimiseVideoCall = () => this.setState({ videoCallFullscreen: false })
  maximiseVideoCall = () => this.setState({ videoCallFullscreen: true })

  setPIRNotice = (PIRNotice) =>
    this.setState({ PIRNotice: PIRNotice })

  setProducts = (prds) => {
    this.setState({ products: prds })
  }

  updateCataloguePrds = () => {
    var { updatePrdsFlag } = this.state
    this.setState({
      updatePrdsFlag: !updatePrdsFlag
    })
  }

  handleToggleCookiesOptionsModal = () => {
    this.setState({ showCookiesOptionsModal: !this.state.showCookiesOptionsModal })
  }

  handleCookiesConsent = (statisticsCookie) => {
    const { cookies } = this.props;

    if (statisticsCookie !== true)
      statisticsCookie = this.state.statisticsCookie

    //this.updateGTMConsent(statisticsCookie)

    let gtm_consent = statisticsCookie ? 'granted' : 'denied'
    window.gtag('consent', 'update', {
      'ad_storage': gtm_consent,
      'analytics_storage': gtm_consent
    })

    cookies.set("cns_necessary", true, { path: "/", maxAge: 3600 * 24 * 30, secure: document.location.protocol.includes("https"), sameSite: "Lax" });
    cookies.set("cns_statistics", statisticsCookie, { path: "/", maxAge: 3600 * 24 * 30, sameSite: "Lax", secure: document.location.protocol.includes("https") });
    this.setState({ statisticsCookie: statisticsCookie })

    if (this.state.showCookiesOptionsModal)
      this.handleToggleCookiesOptionsModal()
  }


  setBackBtn = (back) => {
    this.setState({ backButton: back })
  }

  setVSCusDtls = (tel, email, mtng_id, name, postcode, vsdk_mtng_id, cc_lang) => {
    var VScusDtls = {
      tel: tel,
      email: email,
      mtng_id: mtng_id,
      name: name || null,
      postcode: postcode || null,
      cc_lang: cc_lang || null
    }
    sessionStorage.setItem("VScusDtls", JSON.stringify(VScusDtls))
    this.setState({
      virtual_shopping: true,
      VScusDtls: VScusDtls,
      vsdk_mtng_id: vsdk_mtng_id
    })
  }

  resetVScusDtls = (withBasket) => {
    sessionStorage.removeItem("VScusDtls")
    this.setState(prevState => ({
      virtual_shopping: false,
      videoCallFullscreen: true,
      user: null,
      VScusDtls: null,
      _VScusDtls: this.state.VScusDtls,
      currentView: withBasket ? VIEW_PVSFINISH : prevState.tabletMode ? VIEW_TABLET_STANDBY : VIEW_USER_HOME,
      position: 0,
      offers: [],
      PIRNotice: null,
      customerID: null
    }))
  }

  setView = (view, nextViewAfterAuth = "", anchor, reload = false) => {
    if (this.state.currentView === VIEW_VIDEOCALL) {
      if (this.state.mtngRef || this.state.meetingID)
        this.minimiseVideoCall()
    }
    if (view === VIEW_VIDEOCALL)
      this.maximiseVideoCall()

    let productless = [EVENLY, EVENLYB2C].includes(this.state.ret_details.license.product)
    let productlessHomepage = this.state.vsToken ? VIEW_USER_HOME : VIEW_BOOKING_HOME
    let currentView = view === HOMEPAGE ? (productless ? productlessHomepage : this.state.homepage) : view
    let nextView = nextViewAfterAuth
    const hideCatalogue = this.state.venue_details.hideCatalogue
    let showUserAuthModal = this.state.showUserAuthModal

    if (!evenlyViews.includes(view) && productless)
      view = this.state.homepage;

    if ((securedViews.includes(view)) && !this.getToken() && this.state.ret_details.license.cus_reg) {
      // Allow interpreter to visit video call without authentication
      if (!(view === VIEW_VIDEOCALL && !!this.state.isInterpreter) && !this.state.vsToken) {
        showUserAuthModal = true;
        currentView = this.state.currentView;
        nextView = view;
      }
      if (view === VIEW_BASKET && this.state.vsToken)
        currentView = VIEW_USER_HOME;
    }

    if (!this.state.vsToken && view === VIEW_EVENT_HOST)
      currentView = VIEW_USER_HOME;

    if (hideCatalogue && view === VIEW_CATALOGUE) {
      currentView = VIEW_VENUEHOME
    }

    // Reload page
    if (reload) {
      const ctxPath = this.state.ret_details.with_ctx_path ? window.location.pathname.split("/")[1] : (process.env.PUBLIC_URL.split("/")[1] || "");
      const historyEntryURL = process.env.PUBLIC_URL || window.location.origin + "/" + ctxPath;
      window.location.replace(historyEntryURL);
      return;
    }

    this.setState({
      showNavMenu: false,
      currentView: currentView,
      nextView: nextView,
      previousView: this.state.currentView,
      backButton: currentView === VIEW_CATALOGUE,
      showUserAuthModal: showUserAuthModal,
      userAuthCallback: () => { },
      anchor: anchor
    }, this.clearSearchFlag(currentView));
  }

  updateCatFromPrdView = (newFilters) => {
    this.setState({ prdViewBreadcrumb: newFilters })
  }

  setBreadcrumbFromPrdView = (newBreadcrumb) => {
    this.setState({
      prdViewBreadcrumb: newBreadcrumb,
      currentView: VIEW_CATALOGUE,
      position: 0
    }, this.setBackBtn(false))
  }

  clearSearchFlag = (currentView) => {
    let searchFlag = false

    if (!currentView)
      searchFlag = true
    else
      if (currentView !== VIEW_CATALOGUE)
        searchFlag = true

    this.setState({ clearSearch: searchFlag })
  }

  setOfferDetails = (id, q, prd_id) => {
    this.setState({
      offerID: id,
      quantity: q,
      wshls_prd_id: prd_id
    });
  }

  setScanned = (str, format, source) => {

    if ([BARCODE_FORMAT_EAN_13, BARCODE_FORMAT_QR].indexOf(format) === -1) {
      // AP: Temporarily disable error message for unrecognised barcodes

      // this.setState({
      //   currentView: VIEW_SCANNER,
      //   showIncompatibleQRModal: true
      // })
      return
    }

    // EAN-13
    if (format === BARCODE_FORMAT_EAN_13) {
      const barcode = extract1DBarcode(str, this.state.config.wasp_url)
      this.processBarcode1D(barcode, source, format)
    }

    // QR Code
    if (format === BARCODE_FORMAT_QR) {
      // QR Code may contain barcode value
      if (str.length === 13 && /^\d+$/.test(str)) {
        this.processBarcode1D(str, source)
      } else {
        this.processBarcode2D(str)
      }
    }
  }

  processBarcode1D = (str, source) => {

    if (str.length !== 13 || !(/^\d+$/.test(str))) {
      this.setState({
        currentView: VIEW_SCANNER,
        showIncompatibleQRModal: true
      })
      return
    }

    this.setState({
      barcode: str,
      source: source,
      format: BARCODE_FORMAT_EAN_13
    })

    this.setView(VIEW_PRODUCT)
  }

  processBarcode2D = (str) => {
    const wasp_url = process.env.NODE_ENV === 'production' ? this.state.config.wasp_url : window.location.origin

    try {
      var path_params = parseScannedUrl(str, wasp_url)
      checkQRParams(path_params);
    }
    catch (err) {
      this.setState({
        currentView: VIEW_SCANNER,
        showIncompatibleQRModal: true
      })
      return
    }

    window.history.replaceState({}, "", str);
    this.linkFetch();
    this.resetProducts();
  }

  checkout = (products, vhr_type) => {
    if (vhr_type === undefined) {
      this.setState({
        products_checkout: products,
        currentView: VIEW_CHECKOUT,
      });
      return
    }
  }

  callbackCloseModalError = (childData) => {
    this.setState({
      serverError: childData
    })
  }

  toggleNavMenu = () => {
    this.setState({ showNavMenu: !this.state.showNavMenu });
  }

  setPrevView = (view) => {
    this.setState({ previousView: view });
  }

  resetVenue = () => {
    this.props.cookies.remove("venue");
    this.bootstrapFetch()
  }

  setProductFURL = (furl, src) => {
    this.setState({
      furl: furl,
      format: 11,
      source: src,
      previousView: this.state.currentView,
      from: this.state.currentView,
      currentView: VIEW_PRODUCT
    });
  }

  setCataloguePosition = (arg) => {
    if (typeof arg === "number")
      this.setState({ position: arg })
    else if (this.state.currentView === VIEW_CATALOGUE)
      this.setState({
        position: this.state.backButton ? this.state.position : window.scrollY,
        backButton: false
      })
    else
      window.removeEventListener('scroll', this.throttled, false)
  }

  setUser = (token) => {
    this.setToken(token);
    this.setState({
      user: token,
      showNavMenu: false
    });
  }

  getLandingView = () => {
    var view = VIEW_CATALOGUE
    if (this.state.ret_details.license.product === EVENLY) {
      view = VIEW_BOOKING_HOME
    } else if (this.state.venue_details.hideCatalogue) {
      view = VIEW_VENUEHOME
    }
    return view
  }

  logoutUser = () => {
    const logoutEndpoint = this.state.config.api + "/user/logout";

    // Make a fetch request to the logout endpoint
    fetch(logoutEndpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + this.props.vsToken,
      },
    })
      .catch((error) => {
        console.log('Logout error:', error);
      });

    this.resetVScusDtls();
    this.removeToken();
    this.removeVSToken();
    clearStorageItem(STORAGE_VENUE_FRIENDLY_URL);
    clearStorageItem(STORAGE_VENUE_ID);
    this.setView(VIEW_USER_HOME);
  }

  logout = () => {
    const customerToken = this.getToken()
    // clear customer session when a user is logging out (to maintain compatibility)
    if (!customerToken) {
      this.clearCustomerSession()
      return
    }
    const logoutEndpoint = this.state.config.api + LOGOUT;

    // Make a fetch request to the logout endpoint
    fetch(logoutEndpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + this.getToken(),
      },
    })
      .catch((error) => {
        console.log('Logout error:', error);
      });

    this.clearCustomerSession();
  }

  clearCustomerSession = () => {
    this.removeToken();
    this.setState(prevState => ({
      previousView: "",
      currentView: prevState.vsToken ? VIEW_USER_HOME : this.getLandingView(),
      showNavMenu: false,
      showSavedPrdModal: false,
      loadingLnk: false,
      PIRNotice: null
    }), () => {
      this.clearProducts();
      if (this.state.virtual_shopping || this.state.ars_mode) {
        this.resetVScusDtls();
      }
    })
  }

  clearProducts = () => {
    var prds = this.state.products.list

    prds.length > 0 && prds.forEach(prd => {
      if (prd.qty > 0 || !!prd.po_dtls) {
        prd.qty = 0
        prd.po_dtls = null
      }
    })
    this.setState({
      basketCount: 0,
      products: { ...this.state.products, list: prds }
    })
  }

  resetProducts = () => {
    this.setState({
      updatePrdsFlag: !this.state.updatePrdsFlag,
      products: {
        list: [],
        count: 0,
        offset: 0,
        maxPrice: 0,
      }
    })
  }

  setStorageItem = (key, value) => {
    if (!window.localStorage) {
      const { cookies } = this.props;
      cookies.set(key, value, { sameSite: "Lax", secure: document.location.protocol.includes("https") });
    } else {
      window.localStorage.setItem(key, value);
    }
  }

  getStorageItem = (key) => {
    var value;
    if (!window.localStorage) {
      const { cookies } = this.props;
      value = cookies.get(key);
    } else {
      value = window.localStorage.getItem(key);
    }
    return value;
  }

  removeStorageItem = (key) => {
    if (!window.localStorage) {
      const { cookies } = this.props;
      cookies.remove(key);
    } else {
      window.localStorage.removeItem(key);
    }
  }

  setToken = (token) => {
    this.setStorageItem(this.state.tokenName.cus, token)
  }

  getToken = () => {
    return this.getStorageItem(this.state.tokenName.cus)
  }

  removeToken = () => {
    this.removeStorageItem(this.state.tokenName.cus)
  }

  setBasketCount = (count) => {
    this.setState({ basketCount: count });
  }

  clearProductOffers = () => {
    this.setState({
      offers: [],
      basketCount: 0
    })
  }

  setOrderRef = (ref) => {
    this.setState({ orderRef: ref })
  }

  completeOrderRef = (ref) => {
    this.setState({
      orderRef: ref,
      orderRefCompleted: ref
    }, () => { this.setView(VIEW_ORDER_REVIEW) })
  }

  handleCloseIncompatibleQRModal = () => {
    this.setState({
      showIncompatibleQRModal: false
    })
  }

  handleOpenReloadModal = () => {
    this.setState({
      showReloadModal: true
    })
  }

  resetOrderCompleted = () => {
    this.setState({
      orderRefCompleted: null
    })
  }

  setFriendlyURL = (friendly_url) => {
    this.setState({
      furl: friendly_url,
    })
  }

  getQueryParam = (param) => {
    var query = window.location.href.split('?')[1];
    if (!query) return
    var vars = query.split('&');
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split('=');
      if (decodeURIComponent(pair[0]) === param && !!pair[1]) {
        return decodeURIComponent(pair[1]);
      }
    }
  }

  setVSUser = (data, callback) => {
    localStorage.setItem(this.state.tokenName.admin, data.vsToken);
    localStorage.setItem(this.state.agentName, data.vsUsrNme);
    localStorage.setItem(this.state.isInterpreterName, data.isInterpreter);
    this.setState({
      ars_mode: true,
      vsToken: data.vsToken,
      vsUsrNme: data.vsUsrNme,
      isInterpreter: data.isInterpreter,
    }, callback)
  }

  getVSUser = () => {
    return {
      token: this.state.vsToken
    }
  }

  removeVSToken = () => {
    localStorage.removeItem(this.state.tokenName.admin);
    localStorage.removeItem(this.state.agentName);
    localStorage.removeItem(this.state.isInterpreterName);
    this.setState({
      // ars_mode: false,
      callEnded: false,
      userJoined: false,
      vsToken: null,
      vsUsrNme: null,
      isInterpreter: false
    })
  }

  clearMtngID = () => {
    this.setState({ mtng_id: null })
  }

  getAPIEndpoint = () => {
    if (process.env.REACT_APP_API_ENDPOINT)
      return process.env.REACT_APP_API_ENDPOINT
    return "/api"
  }

  getPenmanEndpoint = () => {
    if (process.env.REACT_APP_PENMAN_ENDPOINT)
      return process.env.REACT_APP_PENMAN_ENDPOINT
    return "/stt"
  }

  getPenmanTransEndpoint = () => {
    if (process.env.REACT_APP_PENMAN_TRANS_ENDPOINT)
      return process.env.REACT_APP_PENMAN_TRANS_ENDPOINT
    return "/stt/translate"
  }

  setAPIEndpoint = (api) => {
    this.setState({
      config: {
        ...this.state.config,
        api: api
      }
    })
  }

  handleCloseVenueScannedModal = () => {
    this.setState({ venueScanned: false })
  }

  setEndMeeting = (end) => {
    this.setState({
      endMeeting: end
    })
  }

  setSendChatMessage = (send) => {
    this.setState({
      sendChatMessage: send
    })
  }

  setCallEnded = (withBasket, isCallManager, hasAlreadyEnded) => {
    const isCustomer = !!this.getToken();
    this.setState(prevState => ({
      callEnded: true,
      userJoined: false,
      cusJoined: false,
      mtngRef: !isCustomer ? null : prevState.mtngRef,
      meetingID: !isCustomer ? null : prevState.meetingID,
      customerID: null,
      withBasket: withBasket,
      vsdk_mtng_id: null,
      tabletMode: false
    }), () => {
      if (isCallManager && !hasAlreadyEnded) // meeting session already ended remotely (videosdk api)
        this.state.endMeeting();
      if (this.state.vsToken && (this.state.isInterpreter || (this.state.instantCall && !withBasket)) && !this.state.tabletMode)
        this.setView(VIEW_USER_HOME);
      else if (!this.state.vsToken || this.state.tabletMode)
        this.setView(VIEW_VIDEOCALL_END);
    })
  }

  handleShowLeaveMeetingModal = () => {
    this.setState({
      leaveMeeting: true
    })
  }

  leaveMeeting = (isCallManager) => {
    this.setState(prevState => ({
      leaveMeeting: false, mtngRef: null, meetingID: null, customerID: null, vsdk_mtng_id: null,
      currentView: isCallManager ? (prevState.tabletMode ? VIEW_TABLET_STANDBY : VIEW_USER_HOME) : VIEW_VIDEOCALL_END,
      cusJoined: false, userJoined: false,
      tabletMode: false
    }), () => {
      if (isCallManager) {
        this.resetVScusDtls();
        this.removeToken();
      }
    });
  }

  userJoined = (vsdk_mtng_id) => {
    let body = { id: vsdk_mtng_id }, params = ""
    if (this.state.isInterpreter && !this.state.vsToken && !this.state.instantCall) {
      body.inter = true
      params = "?inter=true"
    }
    fetch(this.state.config.api + USER_JOINED + params, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        'Authorization': "Bearer " + this.state.vsToken,
        'Content-Type': 'application/json'
      }
    })
      .then(response => {
        if (response.status === 200)
          this.setState({
            userJoined: true
          });
        else
          return Promise.reject({ status: response.status, statusText: response.statusText, appBC: response.headers.get('App-BC') })
      })
      .catch((error) => {
        if (error.timeout)
          this.setState({ serverError: true });
        else if (error.status === 401)
          this.logoutUser();
        else if (error.outdated) {
          this.setRetDetails({}, this.bootstrap)
          this.handleOpenReloadModal()
        }
        else
          this.setView(VIEW_ERROR);
      });
  }

  cusJoined = (vsdk_mtng_id) => {
    fetch(this.state.config.api + CUS_JOINED, {
      method: 'POST',
      body: JSON.stringify({ id: vsdk_mtng_id }),
      headers: {
        'Authorization': "Bearer " + this.getToken(),
        'Content-Type': 'application/json'
      }
    })
      .then(response => {
        if (response.status === 200)
          this.setState({
            cusJoined: true
          });
        else
          return Promise.reject({ status: response.status, statusText: response.statusText, appBC: response.headers.get('App-BC') })
      })
      .catch((error) => {
        if (error.timeout)
          this.setState({ serverError: true });
        else if (error.status === 401)
          this.logout();
        else if (error.outdated) {
          this.setRetDetails({}, this.bootstrap)
          this.handleOpenReloadModal()
        }
        else
          this.setView(VIEW_ERROR);
      });
  }

  eb2cJoined = (vsdk_mtng_id) => {
    if (!vsdk_mtng_id) return; // TBD - See Evenly B2C transcription
    fetch(this.state.config.api + EB2C_JOINED, {
      method: 'POST',
      body: JSON.stringify({ id: vsdk_mtng_id }),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        if (response.status !== 200)
          return Promise.reject({ status: response.status, statusText: response.statusText, appBC: response.headers.get('App-BC') })
      })
      .catch((error) => {
        if (error.timeout)
          this.setState({ serverError: true });
        else if (error.status === 401)
          this.logout();
        else if (error.outdated) {
          this.setRetDetails({}, this.bootstrap)
          this.handleOpenReloadModal()
        }
        else
          this.setView(VIEW_ERROR);
      });
  }

  setCurrentMeetingDetails = (m) => {
    this.setState({
      currentMeetingDetails: m
    })
  }

  setMtngID = (id, instantCall = true, directCall = false) => {
    this.setState({
      meetingID: id,
      instantCall: instantCall,
      directCall: directCall
    });
  }

  setCustomerID = (id) => {
    this.setState({
      customerID: id,
    });
  }

  setShowInstantCallRequestModal = () => {
    this.setState(prevState => ({
      showInstantCallRequestModal: !prevState.showInstantCallRequestModal,
      instantCallPopup: false,
      instantVenue: prevState.instantCallPopup ? prevState.venue_details.venue : null
    }));
  }

  setShowInstantChatRequestModal = () => {
    this.setState(prevState => ({
      showInstantChatRequestModal: !prevState.showInstantChatRequestModal,
      instantChatPopup: false,
      instantVennue: prevState.instantChatPopup ? prevState.venue_details.venue : null
    }));
  }

  handleShowUserAuthModal = (callback) => {
    this.setState({
      showUserAuthModal: true,
      userAuthCallback: callback
    });
  }

  handleCloseUserAuthModal = () => {
    this.setState({
      showUserAuthModal: false
    });
  }

  toggleShowCallDetailsModal = () => {
    this.setState(prevState => ({
      showCallDetailsModal: !prevState.showCallDetailsModal
    }));
  }

  toggleShowCallTroubleshootingModal = () => {
    this.setState(prevState => ({
      showCallTroubleshootingModal: !prevState.showCallTroubleshootingModal
    }));
  }

  handleLegal = (section, e) => {
    e.preventDefault();
    const wasp_url = process.env.NODE_ENV === 'production' ? this.state.config.wasp_url : window.location.origin;
    window.open(wasp_url + "/legal/" + section, "_blank");
  }

  setNotificationSound = (ns) => {
    this.setState({
      notificationSound: ns
    });
  }

  handleShowWorkingHoursModal = (srv) => {
    this.setState({
      showWorkingHoursModal: true,
      instantServiceRequested: srv
    });
  }

  handleCloseWorkingHoursModal = () => {
    this.setState({
      showWorkingHoursModal: false
    });
  }

  getPreferredLanguage = () => {
    const checkLang = (lang) => { return this.state.ret_details.langs.includes(lang) ? lang : null; }
    // Priority: 1. query param 2. browser 3. default
    return checkLang(this.getQueryParam("lang")) || checkLang(i18n.language.split("-")[0]) || checkLang(this.state.ret_details.def_lang);
  }

  sortCCLangs = () => {
    const { ret_details } = this.state;
    const sortedCCLangs = ret_details.cc_langs.sort(compareLangLabel);
    this.setState({
      ret_details: { ...ret_details, cc_langs: sortedCCLangs }
    });
  }

  setShowAudioOnlyRequestModal = () => {
    this.setState(prevState => ({
      showAudioOnlyRequestModal: !prevState.showAudioOnlyRequestModal
    }));
  }
}

function suspenseful(component) {
  return <Suspense fallback={<div></div>}>{component}</Suspense>
}

function changePageTitle(retailer, isEvenly, customDomainName) {
  if (customDomainName)
    document.title = retailer;
  else if (isEvenly)
    document.title = "Evenly - " + retailer;
  else
    document.title = "Valuelenz - " + retailer;
}

function changeBackgroundColor(currentView) {
  const toReplace = videoCallViews.includes(currentView) ? ["bg-light", "bg-dark"] : ["bg-dark", "bg-light"];
  if (document.body.classList.value.includes(toReplace[0]))
    document.body.classList.replace(toReplace[0], toReplace[1]);
}

export default withTranslation()(withCookies(App));
