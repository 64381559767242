import React, { useEffect, useRef, useState } from "react";
import { withTranslation } from 'react-i18next';
import { VIEW_ERROR } from '../../constants/views.js';
import { Button, Input, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import CallWaiting from './CallWaiting';
import { liveChatIcon } from "../../util/Util.js";

const NEW_INSTANT_CALL = "/instant";
const CUSTOMER = "/customer";

const InstantChat = (props) => {
  const { api, user, logout, openReloadModal, setView, t, extraFields } = props;
  const [showCallWaitingModal, setShowCallWaitingModal] = useState(false);
  const [waitingRemoteParticipants, setWaitingRemoteParticipants] = useState({ agent: false });
  const [channel, setChannel] = useState(null);
  const [showCallRequestModal, setShowCallRequestModal] = useState(props.visible);
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [extraFieldsValues, setExtraFieldsValues] = useState(null);
  const [requestCallMessage, setRequestCallMessage] = useState(null);

  const customerDetailsRef = useRef(false);

  // initialise extra fields
  useEffect(() => {
    if (!extraFields)
      return
    extraFields.forEach(ef => setExtraFieldsValues((prevState) => ({ ...prevState, [ef]: "" })))
  }, [extraFields])

  useEffect(() => {
    setShowCallRequestModal(props.visible);
  }, [props.visible])

  useEffect(() => {
    if (!user)
      customerDetailsRef.current = false
    if (!showCallRequestModal || customerDetailsRef.current)
      return;
    const setCusDtls = (data) => {
      setFname(data.fnme);
      setLname(data.lnme);
      setEmail(data.email_addr);
      customerDetailsRef.current = true
    }
    getCustomerDetails({ api, user, logout, openReloadModal, setView, setCusDtls });
  }, [showCallRequestModal, api, user, logout, openReloadModal, setView]);

  const handleNewInstantCallRequest = () => {
    setShowCallRequestModal(false);
    newInstantCall({
      ...props, setChannel: setChannel,
      setShowCallWaitingModal: setShowCallWaitingModal,
      fname: fname, lname: lname, email: email, lang: props.i18n.language || 'en',
      setRequestCallMessage: setRequestCallMessage,
      setWaitingRemoteParticipants: setWaitingRemoteParticipants,
      extraFields: extraFieldsValues
    });
  }

  const customClass = "col-sm-12 col-md-5 col-form-label";

  // NB: extra field keys (in ret table) must match the booking form's as they share the same translations
  const extraFieldsContent = extraFieldsValues && extraFields?.map(field => {
    return (
      <div key={field} className="form-group row">
        <label htmlFor={field} className={customClass}>{t(`booking.extraFields.${field}.label`)}</label>
        <div className="col-md-7 mb-3">
          <Input autoComplete="dummy" type="text" className="form-control" id={field} value={extraFieldsValues[field]} placeholder={t(`booking.extraFields.${field}.label`)} onChange={(e) => setExtraFieldsValues((prevState) => ({ ...prevState, [field]: e.target.value }))} />
        </div>
      </div>
    )
  })

  const callRequestModal =
    <Modal index={"auto"} centered={true} isOpen={showCallRequestModal}>
      <ModalHeader toggle={() => props.setVisible(false)}>
        {props.bcc ? <img src={liveChatIcon} width={30} className="mb-1 mr-2" /> : <i className="fas fa fa-comment fa-sm mr-2"></i>}
        {t('instantChat.requestModal.title')}
      </ModalHeader>
      <ModalBody>
        <div className="form-group row">
          <label htmlFor="" className={customClass}>{t('booking.form.fname.label')}</label>
          <div className="col-md-7 mb-3">
            <Input autoComplete="dummy" type="text" className="form-control" id="fname" value={fname} placeholder={t('booking.form.fname.label')} onChange={(e) => setFname(e.target.value)} />
          </div>
        </div>
        <div className="form-group row">
          <label htmlFor="" className={customClass}>{t('booking.form.lname.label')}</label>
          <div className="col-md-7 mb-3">
            <Input autoComplete="dummy" type="text" className="form-control" id="lname" value={lname} placeholder={t('booking.form.lname.label')} onChange={(e) => setLname(e.target.value)} />
          </div>
        </div>
        {extraFieldsContent}
        <div className="form-group row">
          <label htmlFor="instant-email" className={customClass}>{t('booking.form.email.label')}</label>
          <div className="col-md-7 mb-3">
            <Input type="email" className="form-control" id="instant-email" name="email" value={email} placeholder={t('booking.form.email.label')} onChange={(e) => setEmail(e.target.value)} />
          </div>
        </div>

      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={handleNewInstantCallRequest}>{t('instantChat.requestModal.submitBtn')}</Button>
      </ModalFooter>
    </Modal>;

  return (
    <>
      {showCallWaitingModal &&
        <CallWaiting
          visible={props.visible}
          setVisible={() => props.setVisible(false)}
          user={props.user}
          api={props.api}
          uuid={channel}
          setView={props.setView}
          setMtngID={props.setMtngID}
          isCustomer={true}
          instant={props.instant}
          requestCallMessage={requestCallMessage}
          phone={props.phone}
          chat={true}
          waitingRemoteParticipants={waitingRemoteParticipants}
          bcc={props.bcc}
        />}
      {callRequestModal}
    </>
  )
}

function newInstantCall(props) {
  const { api, user, logout, openReloadModal, setView, setChannel, setShowCallWaitingModal, fname, lname, email, lang, setRequestCallMessage, venue, setWaitingRemoteParticipants, extraFields } = props;

  let extraFieldsBody = extraFields ? JSON.stringify(extraFields) : undefined
  fetch(`${api}${NEW_INSTANT_CALL}`, {
    method: "POST",
    headers: { 'Authorization': "Bearer " + user, 'Content-Type': 'application/json' },
    body: JSON.stringify({
      chat: true,
      venue_id: venue.toString(),
      agent: true,
      fname: fname,
      lname: lname,
      email: email,
      lang: lang,
      extra_fields: extraFieldsBody
    })
  }).then(response => {
    if (response.status === 200)
      return response.json();
    return Promise.reject({ status: response.status, statusText: response.statusText });
  }).then((data) => {
    setChannel(data.channel);
    setWaitingRemoteParticipants({
      "agent": data.message.agent,
      "interpreter": data.message.interpreter
    })
    setShowCallWaitingModal(true);
    if (data.message)
      setRequestCallMessage(data.message);
  }).catch((error) => {
    if (error.status === 401)
      logout();
    else if (error.outdated)
      openReloadModal();
    else
      setView(VIEW_ERROR);
  })
}

function getCustomerDetails(props) {
  const { api, user, logout, openReloadModal, setView, setCusDtls } = props;
  fetch(`${api}${CUSTOMER}`, {
    method: "GET",
    headers: { 'Authorization': "Bearer " + user, 'Content-Type': 'application/json' },
  }).then(response => {
    if (response.status === 200)
      return response.json();
    return Promise.reject({ status: response.status, statusText: response.statusText });
  }).then((data) => {
    setCusDtls(data);
  }).catch((error) => {
    if (error.status === 401)
      logout();
    else if (error.outdated)
      openReloadModal();
    else
      setView(VIEW_ERROR);
  })
}

export default withTranslation()(InstantChat);