export const parsePathParameters = (str, path_url) => {
  var res = {
    friendly_url: "",
  };

  str = str.replace(/\?.*$/, "")

  var path = "";
  if (str.startsWith(path_url)) {
    path = str.substring(path_url.length);
  }
  path = path.replace(/^\//, '');

  var path_params = path.split("/");

  if (path_params.length === 0) {
    return res;
  }

  res.friendly_url = path_params[0];

  if (res.friendly_url === "") {
    return res;
  }

  return res;
}

export const extract1DBarcode = (str, path_url) => {
  if (/^\d+$/.test(str)) {
    return str
  }

  var res = "";

  str = str.replace(/\?.*$/, "")

  var path = "";
  if (str.startsWith(path_url)) {
    path = str.substring(path_url.length);
  }
  path = path.replace(/^\//, '');

  var path_params = path.split("/");

  if (path_params.length !== 1) {
    return res;
  }

  res = path_params[0]

  if (res === "")
    return res

  return res
}

export const parseScannedUrl = (str, path_url) => {
  const href = str.toLowerCase()

  // Extract friendly url.
  const url = new URL(href);
  if (!(window.location.origin === url.origin)) {
    throw new Error("Invalid URL");
  }

  var res = parsePathParameters(href, path_url);

  return res;
}

export const checkQRParams = (qr_params) => {
  if (qr_params.friendly_url === "") throw new Error();
  return;
}
