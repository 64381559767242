import React, { Component } from 'react';

class FooterLogo extends Component {
  render() {

    return (
      <div className="container footer-brand">
        <div className="col-md-12">
          <div className="p-5 text-center col-10 offset-1 col-sm-8 offset-sm-2 col-md-4 offset-md-4">
            <img src={this.props.logo} className="umbrella-logo-lower" alt={this.props.retailer} />
          </div>
        </div>
      </div>
    );
  }
}

export default FooterLogo;
